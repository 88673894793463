import { Box } from '@mui/material';
import { likeValue } from '../../data/constants';
import { useEffect, useState } from 'react';
import FeedbackModal from './FeebackModal';
import BusinessIdeaComponent from './BusinessIdeaComponent';
import { useSelector } from 'react-redux';

const BusinessIdeasContainer = ({
  handleLikeDislike = () => { },
  handleIdeaLock = () => { },
  handleIdeaUndo = () => { },
  handleVariantOption = () => { },
  handleIdeaShare = () => { },
  isGuest,
}) => {
  const ideasData = useSelector(
    (state) => state?.researchData?.ideasList
  );
  const [changableIdeas, setChangableIdeas] = useState([]);
  const [nonChangableIdeas, setNonChangableIdeas] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState({
    status: false,
    feedbackType: null,
    ideaDetail: null,
    ideaLockStatus: null,
  });
  const handleChange = (actionType, ideaHandled, ideaLikeDislike) => {
    if (actionType === ideaLikeDislike) {
      handleLikeDislike(actionType, ideaHandled?.id, ideaLikeDislike, ' ');
    } else {
      setShowFeedbackModal({
        status: true,
        feedbackType: actionType,
        ideaDetail: ideaHandled?.id,
        ideaLockStatus: ideaHandled?.locked,
      });
    }
  };
  const addFeedback = (actionType, ideaHandled, feedback) => {
    handleLikeDislike(
      actionType,
      ideaHandled,
      ideaHandled?.likedislike,
      feedback
    );
    setShowFeedbackModal({
      status: false,
      ideaDetail: null,
      feedbackType: null,
      ideaLockStatus: null,
    });
  };
  const separateIdeas = () => {
    if (ideasData?.length) {
      let nonChangableIdeas = [];
      let changableIdeas = [];
      ideasData.forEach((idea) => {
        if (
          idea?.type === 'Manual' ||
          idea?.locked === 'true' ||
          idea?.likedislike === likeValue
        ) {
          nonChangableIdeas.push(idea);
        } else {
          changableIdeas.push(idea);
        }
      });
      setNonChangableIdeas([...nonChangableIdeas]);
      setChangableIdeas([...changableIdeas]);
    }
  };
  useEffect(() => {
    separateIdeas(ideasData);
  }, [ideasData]);
  return (
    <>
      <Box>
        {nonChangableIdeas.length !== 0 &&
          nonChangableIdeas.map((ideaOption, index) => (
            <Box key={'ideaOptionContainer' + index}>
              <BusinessIdeaComponent
                handleChange={handleChange}
                handleIdeaLock={handleIdeaLock}
                ideaOption={ideaOption}
                key={'ideaOption' + index}
                dividerPosition={'top'}
                handleVariantOption={handleVariantOption}
                handleIdeaShare={handleIdeaShare}
                isGuest={isGuest}
              />
              {ideaOption?.children?.length !== 0 &&
                ideaOption?.children?.map((childIdea, index) => (
                  <BusinessIdeaComponent
                    handleChange={handleChange}
                    handleIdeaLock={handleIdeaLock}
                    ideaOption={childIdea}
                    key={'childIdeaOption' + index}
                    dividerPosition={'top'}
                    handleVariantOption={handleVariantOption}
                    handleIdeaShare={handleIdeaShare}
                    isChild={true}
                    isGuest={isGuest}
                  />
                ))}
            </Box>
          ))}
      </Box>
      <Box className="mt1 borderTopGrey">
        {changableIdeas.length !== 0 &&
          changableIdeas.map((ideaOption, index) => (
            <Box key={'ideaComponent' + index}>
              <BusinessIdeaComponent
                handleChange={handleChange}
                handleIdeaLock={handleIdeaLock}
                handleIdeaUndo={handleIdeaUndo}
                ideaOption={ideaOption}
                key={'ideaOption' + index}
                dividerPosition={'bottom'}
                handleVariantOption={handleVariantOption}
                handleIdeaShare={handleIdeaShare}
                isGuest={isGuest}
              />
              {ideaOption?.children?.length !== 0 &&
                ideaOption?.children?.map((childIdea, index) => (
                  <BusinessIdeaComponent
                    handleChange={handleChange}
                    handleIdeaLock={handleIdeaLock}
                    ideaOption={childIdea}
                    key={'ideaOption' + index}
                    dividerPosition={'bottom'}
                    handleVariantOption={handleVariantOption}
                    handleIdeaShare={handleIdeaShare}
                    isChild={true}
                    isGuest={isGuest}
                  />
                ))}
            </Box>
          ))}
      </Box>
      {showFeedbackModal && (
        <FeedbackModal
          showModal={showFeedbackModal.status}
          ideaDetail={showFeedbackModal.ideaDetail}
          ideaLockStatus={showFeedbackModal.ideaLockStatus}
          feedbackType={showFeedbackModal.feedbackType}
          handleFeedbackSubmit={addFeedback}
          handleClose={() => {
            setShowFeedbackModal({
              status: false,
              ideaDetail: null,
              feedbackType: null,
              ideaLockStatus: null,
            });
          }}
        />
      )}
    </>
  );
};
export default BusinessIdeasContainer;
