import { Button, Grid, Typography, Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import { setUserDetails } from '../../redux/login';
const Home = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useAuth();
  useEffect(() => {
    if (user) {
      dispatch(setUserDetails(user));
      navigate('/dashboard', { replace: true });
    }
  }, [user, dispatch, navigate]);
  return (
    <Grid>
      <Grid item sm={12} justifyContent={'center'}>
        <Typography
          component={'h1'}
          mt={2}
          ml={2}
          mb={1}
          fontSize={48}
          color={'primary'}
          fontWeight={'bold'}
          textAlign={'center'}
        >
          Pathways
        </Typography>
        <Typography
          fontWeight={'bold'}
          color={'gray'}
          fontSize={24}
          mt={4}
          textAlign={'center'}
          mx={2}
        >
          Building your future Pathways using{' '}
          <Typography
            component={'span'}
            fontSize={24}
            fontWeight={'bold'}
            color={'primary'}
          >
            A.I.
          </Typography>
        </Typography>
        <Box textAlign={'center'} mt={3}>
          <Button
            onClick={() => {
              navigate('/login');
            }}
            variant="contained"
            sx={{ margin: '0px 10px' }}
          >
            Login
          </Button>
          <Button
            onClick={() => {
              navigate('/signup');
            }}
            variant="contained"
          >
            Signup
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
