import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { labels } from '../../data/constants';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { useState } from 'react';
import AddIdeaModal from './AddIdeaModal';

import { addManualBusinessIdeaService } from '../../Services/businessIdeasService';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useSelector } from 'react-redux';
const BusinessIdeasHeader = ({
  fetchIdeaList = () => {},
  generateIdeas = () => {},
  loader = false,
  isGuest = false,
}) => {
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showModal, setShowModal] = useState(false);
  const isInputModified = false;
  const isUpToDate = true;
  const manualIdeaSubmit = async (ideaName, ideaSummary) => {
    let payload = {
      idea: ideaName,
      summary: ideaSummary,
      workspaceId: +userWorkspace.id,
    };
    const response = await addManualBusinessIdeaService(payload);
    if (response) {
      setShowModal(false);
      fetchIdeaList();
    } else {
      console.log(response);
    }
  };
  return (
    <Grid container>
      <Grid item lg={10} display={'flex'} alignItems={'center'}>
        <Typography component={'h1'} variant="dashboardCompHeading">
          {labels.businessIdeas.title}
        </Typography>
        {loader === false ? (
          <IconButton
            onClick={generateIdeas}
            aria-label={labels.businessIdeas.businessIdeasHeader.refreshIdeas}
            className="iconButtonType2 ml1"
            disabled={isGuest}
          >
            <AutorenewIcon className="mr05" />
            {labels.businessIdeas.businessIdeasHeader.refreshIdeas}
          </IconButton>
        ) : (
          <Box className="displayFlex alignItemsCenter ml05">
            <CircularProgress size={'1rem'} />
            <Typography className="ml05" variant="paraText1">
              {labels.businessIdeas.businessIdeasHeader.generatingIdeas}
            </Typography>
          </Box>
        )}

        {/* {isInputModified && (
          <Box display={'flex'}>
            <IconButton
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexFlow: 'row',
                WebkitBoxAlign: 'center',
                alignItems: 'center',
                WebkitBoxPack: 'start',
                justifyContent: 'flex-start',
                gap: '4px',
                marginRight: '12px',
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                WebkitTapHighlightColor: 'transparent',
                color: 'inherit',
                fontSize: '16px',
                lineHeight: '1.55',
                textDecoration: 'none',
                fontWeight: '400',
              }}
            >
              <ErrorOutlineIcon />
              Inputs Modified
            </IconButton>
            <IconButton
              sx={{
                padding: '0px 18px',
                appearance: 'none',
                textAlign: 'left',
                textDecoration: 'none',
                boxSizing: 'border-box',
                height: '36px',
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                WebkitTapHighlightColor: 'transparent',
                display: 'inline-flex',
                width: 'auto',
                borderRadius: '4px',
                fontWeight: '600',
                position: 'relative',
                lineHeight: '1',
                fontSize: '14px',
                userSelect: 'none',
                cursor: 'pointer',
                border: '1px solid transparent',
                backgroundColor: 'rgb(77, 13, 245)',
                color: 'rgb(255, 255, 255)',
                marginRight: '12px',
                alignItems: 'center',
              }}
            >
              <LoopIcon />
              Refresh Ideas
            </IconButton>
          </Box>
        )}
        {isUpToDate && (
          <IconButton>
            <CheckCircleOutlineIcon />
            Up to Date
          </IconButton>
        )} */}
      </Grid>
      <Grid item lg={2}>
        <IconButton
          onClick={() => {
            setShowModal(true);
          }}
          disabled={isGuest}
          aria-label={labels.businessIdeas.businessIdeasHeader.addIdea}
          className="iconButtonType2"
        >
          <AddIcon />
          {labels.businessIdeas.businessIdeasHeader.addIdea}
        </IconButton>
        <IconButton
          id="help-menu-button"
          aria-controls={open ? 'businessIdeas-help-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <HelpOutlineIcon fontSize="medium" />
        </IconButton>
        <Menu
          id="businessIdeas-help-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'help-menu-button',
          }}
        >
          <MenuItem className="customMenuItem2">
            <ListItemIcon>
              <LockOutlinedIcon fontSize="small" className="colorBlack" />
            </ListItemIcon>
            <ListItemText className="listItemText1">
              {labels.businessIdeas.businessIdeasHeader.helpText1}
            </ListItemText>
          </MenuItem>
          <MenuItem className="customMenuItem2">
            <ListItemIcon>
              <ThumbUpAltOutlinedIcon fontSize="small" className="colorBlack" />
            </ListItemIcon>
            <ListItemText className="listItemText1">
              {labels.businessIdeas.businessIdeasHeader.helpText2}
            </ListItemText>
          </MenuItem>
          <MenuItem className="customMenuItem2">
            <ListItemIcon>
              <ThumbDownAltOutlinedIcon
                fontSize="small"
                className="colorBlack"
              />
            </ListItemIcon>
            <ListItemText className="listItemText1">
              {labels.businessIdeas.businessIdeasHeader.helpText3}
            </ListItemText>
          </MenuItem>
          <MenuItem className="customMenuItem2">
            <ListItemIcon>
              <AltRouteIcon fontSize="small" className="colorBlack" />
            </ListItemIcon>
            <ListItemText className="listItemText1">
              {labels.businessIdeas.businessIdeasHeader.helpText4}
            </ListItemText>
          </MenuItem>
        </Menu>
      </Grid>
      {showModal && (
        <AddIdeaModal
          showAddIdeaModal={showModal}
          handleCancel={() => {
            setShowModal(false);
          }}
          handleIdeaSubmit={manualIdeaSubmit}
        />
      )}
    </Grid>
  );
};
export default BusinessIdeasHeader;
