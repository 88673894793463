import { Typography, Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomTabs from '../../Components/Custom Components/CustomTabs/CustomTab';
import StudentsTabContent from '../../Components/Students/StudentsTabContent';
import { useNavigate } from 'react-router-dom';
import { labels } from '../../data/constants';
import CustomDialog from '../../Components/Custom Components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  aiScholarshipMathService,
  fetchCollegeProgramList,
  fetchEducationalPathList,
  fetchScholarshipList,
  getStudentsTabsDetails,
  saveAnswers,
  suggestCollegeProgramService,
  suggestEducationalPathwayService,
} from '../../Services/studentsServices';
import StudentsSkeleton from '../../Components/Students/StudentsSkeleton';

const StudentsComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const userProfile = useSelector(
    (state) => state?.loginUserData?.user?.userDetails
  );

  const [showSkeleton, setShowSkeleton] = useState(true);
  const [studentsTabStructure, setStudentsTabStructure] = useState({
    tabs: [],
  });
  const [studentsTabStructureOriginal, setStudentsTabStructureOriginal] =
    useState({ tabs: [] });
  const [selectedTab, setSelectedTab] = useState(0);

  //ai suggested scholarship data
  const [scholarshipList, setScholarshipList] = useState(null);
  //ai suggested college program data
  const [collegeProgramList, setCollegeProgramList] = useState(null);
  //ai suggested educational paths data
  const [educationalPathwaysList, setEducationalPathwaysList] = useState(null);

  //Array containing positional reference to ai suggested data states as per tab.
  const aiSuggestionData = [
    scholarshipList,
    collegeProgramList,
    educationalPathwaysList,
  ];
  useEffect(() => {
    if (userWorkspace?.hideProfile) {
      navigate('/dashboard');
    } else if (userProfile?.role && userWorkspace?.role !== 'guest') {
      setScholarshipList(null);
      setCollegeProgramList(null);
      setEducationalPathwaysList(null);
      fetchUserTabData();
      //Fetching all generated suggestions by ai
      getEducationPathwayList();
      getRecommendedProgramList();
      getScholarshipList();
    }
  }, [userProfile, userWorkspace]);

  //handler to get scholarship suggestions
  const suggestScholarships = async () => {
    try {
      let payload = {
        workspaceid: userWorkspace?.id,
      };
      const response = await aiScholarshipMathService(payload);
      if (response) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Unable to suggest scholarship:', e);
    }
  };
  //handler to get college program suggestions
  const suggestCollegePrograms = async () => {
    try {
      let payload = {
        workspaceid: userWorkspace?.id,
      };
      const response = await suggestCollegeProgramService(payload);
      if (response) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Unable to suggest college programs:', e);
    }
  };
  //handler to get educational path suggestions
  const suggestEducationalPaths = async () => {
    try {
      let payload = {
        workspaceid: userWorkspace?.id,
      };
      const response = await suggestEducationalPathwayService(payload);
      if (response) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Unable to suggest educational pathways:', e);
    }
  };

  //ai suggestion handlers positioned as per selected tab.
  const aiSuggestionHandlers = [
    suggestScholarships,
    suggestCollegePrograms,
    suggestEducationalPaths,
  ];

  //Handler to get suggestion data
  const getScholarshipList = async () => {
    try {
      let payload = {
        workspaceid: userWorkspace?.id,
      };
      const response = await fetchScholarshipList(payload);
      if (response) {
        setScholarshipList(response?.scholarshipMatch);
      }
    } catch (e) {
      console.error(e);
    }
  };
  //Handler to get suggestion data
  const getRecommendedProgramList = async () => {
    try {
      let payload = {
        workspaceid: userWorkspace?.id,
      };
      const response = await fetchCollegeProgramList(payload, dispatch);
      if (response) {
        setCollegeProgramList(response?.collegeReco);
      }
    } catch (e) {
      console.error(e);
    }
  };
  //Handler to get suggestion data
  const getEducationPathwayList = async () => {
    try {
      let payload = {
        workspaceid: userWorkspace?.id,
      };
      const response = await fetchEducationalPathList(payload, dispatch);
      if (response) {
        setEducationalPathwaysList(response?.educationPath);
      }
    } catch (e) {
      console.error(e);
    }
  };

  //Handlers array as per tabs and their positions
  const asdFetchers = [
    getScholarshipList,
    getRecommendedProgramList,
    getEducationPathwayList,
  ];
  const fetchUserTabData = async () => {
    const payload = { workspaceid: userWorkspace?.id };
    const response = await getStudentsTabsDetails(payload, dispatch);

    if (response && response?.length !== 0) {
      setStudentsTabStructure({ tabs: [] });
      setStudentsTabStructureOriginal({ tabs: [] });

      const mutableResponse = JSON.parse(JSON.stringify(response));
      let tabData = [];

      mutableResponse?.forEach((tabItem) => {
        let answeredQuestion = 0;

        for (let i = 0; i < tabItem?.questions?.length; i++) {
          const question = tabItem?.questions[i];

          if (question?.type === 'multiSelect') {
            let answerStringInArray = question?.answer?.split(',');
            let answerArray = [];

            answerStringInArray?.forEach((item) => {
              if (item.trim() !== '') {
                // Ensure answer is not an empty string or just spaces
                let answerLabel;
                question?.options?.forEach((option) => {
                  if (option?.value === item) {
                    answerLabel = option?.label;
                  }
                });
                if (answerLabel) {
                  answerArray.push({ label: answerLabel, value: item });
                }
              }
            });

            question.answer = answerArray;
            if (answerArray?.length > 0) {
              answeredQuestion++;
            }
          } else if (question?.type === 'select') {
            let answerObject;
            question?.options?.forEach((option) => {
              if (option?.value === question?.answer) {
                answerObject = {
                  label: option?.label,
                  value: question?.answer,
                };
              }
            });

            question.answer = answerObject;
            if (answerObject) {
              answeredQuestion++;
            }
          } else {
            // For other types like textFieldMulti, check if the answer is a valid string
            if (question?.answer && question?.answer.trim() !== '') {
              answeredQuestion++;
            }
          }
        }

        let tabDataItem = {
          ...tabItem,
          completionPercentage:
            (answeredQuestion / tabItem?.questions?.length) * 100,
        };
        tabData.push(tabDataItem);
      });

      setStudentsTabStructure({ tabs: [...tabData] });
      let stringifiedData = JSON.stringify(tabData);
      setStudentsTabStructureOriginal({ tabs: JSON.parse(stringifiedData) });
      setShowSkeleton(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDataChange = (questionId, answer) => {
    if (answer === undefined) return;

    const updatedTabs = { ...studentsTabStructure };
    updatedTabs?.tabs[selectedTab]?.questions?.forEach((question) => {
      if (question?.id === questionId) {
        question.answer = answer;
      }
    });
    updateCompletionPercentages(updatedTabs);
  };

  const updateCompletionPercentages = (tabData) => {
    const updatedTabs = tabData?.tabs?.map((tab) => {
      const answeredQuestions = tab?.questions?.reduce((count, question) => {
        if (
          question?.type === 'multiSelect' &&
          Array.isArray(question?.answer) &&
          question?.answer?.length > 0
        ) {
          return count + 1;
        } else if (question?.type === 'select' && question?.answer) {
          return count + 1;
        } else if (
          question?.answer &&
          typeof question?.answer === 'string' &&
          question?.answer?.trim()
        ) {
          return count + 1;
        }
        return count;
      }, 0);

      return {
        ...tab,
        completionPercentage: Math.round(
          (answeredQuestions / tab?.questions?.length) * 100
        ),
      };
    });

    setStudentsTabStructure({ tabs: updatedTabs });
  };

  const isAnswerChanged = (questionId, newAnswer) => {
    return studentsTabStructureOriginal?.tabs?.some((tab) =>
      tab?.questions?.some((question) => {
        if (question?.id === questionId) {
          return JSON.stringify(question?.answer) !== JSON.stringify(newAnswer);
        }
        return false;
      })
    );
  };

  const submitProfileData = async (questionId, answer) => {
    if (!isAnswerChanged(questionId, answer)) return;

    let formattedAnswer;
    if (Array.isArray(answer)) {
      formattedAnswer =
        answer?.length > 0 ? answer?.map((item) => item?.value).join(',') : ' ';
    } else {
      formattedAnswer = answer || ' ';
    }

    const payload = {
      questionId,
      answer: formattedAnswer,
      workspaceId: userWorkspace?.id,
    };

    const response = await saveAnswers(payload, dispatch);
    if (response) {
      fetchUserTabData();
    }
  };

  return (
    <Box className="mt1 ml1 mr1 pb1_5">
      <Box className="displayFlex justifySpaceBetween">
        <Typography
          className="ml1"
          component="h1"
          variant="dashboardCompHeading"
        >
          {labels.students.title}
        </Typography>
      </Box>

      <Box
        ml={2}
        sx={{ overflow: 'hidden', minWidth: '1px', maxWidth: '85vw' }}
      >
        <CustomTabs
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          tabData={studentsTabStructure?.tabs}
        />
      </Box>

      {selectedTab !== undefined && !showSkeleton && userProfile?.role && (
        <StudentsTabContent
          tabDataHandler={handleDataChange}
          submitAnswer={submitProfileData}
          tabContent={studentsTabStructure.tabs[selectedTab]}
          aiSuggestionData={aiSuggestionData[selectedTab]} //passing suggestion data as per selected tab
          aiSuggestionHandler={aiSuggestionHandlers[selectedTab]} //assigning ai suggestion handler as per tab
          asdFetcher={asdFetchers[selectedTab]} //assigning suggestion data fetcher as per tab
        />
      )}

      {showSkeleton && <StudentsSkeleton />}

      {userWorkspace?.role === 'guest' && (
        <CustomDialog handleOpen={true} showClose={false}>
          <Typography textAlign="center" fontWeight="bold">
            Access Denied
          </Typography>
          <Typography textAlign="center" mt={2}>
            Access to profile in guest workspace is denied
          </Typography>
          <Box textAlign="center" mt={3}>
            <Button
              variant="buttonVariant1"
              onClick={() => navigate('/dashboard')}
            >
              Go to Home
            </Button>
          </Box>
        </CustomDialog>
      )}
    </Box>
  );
};

export default StudentsComp;
