import ApiConfig from '../config/ApiConfig';
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from '../data/constants';
import axios from 'axios';
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';
import { saved, saving, couldnotSave, resetStatus } from '../redux/saveSlice';
import { setStudentsData } from '../redux/studentsSlice';

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const getStudentsTabsDetails = async (payload, dispatch) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.studentsTabsAndDetails}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(setStudentsData(resp?.data?.data));
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const saveAnswers = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.saveAnswerForStudentsQuestions}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    showErrorMessage(e);
    return false;
  }
};

export const aiScholarshipMathService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.scholarShipMatchAI}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const suggestCollegeProgramService = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.collegeProgramMatchAI}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const suggestEducationalPathwayService = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.educationalPathMatchAI}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const fetchScholarshipList = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.scholarShipList}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const fetchCollegeProgramList = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.recommendedCollegeList}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const fetchEducationalPathList = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.educationalPathsList}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
