import { Box, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import { useEffect, useState } from 'react';
import {
  addTopIdeaService,
  ideaRatingService,
  listIdeaGrade,
  listIdeaService,
} from '../../Services/FilterService';
import { useSelector, useDispatch } from 'react-redux';
import IdeasBlock2 from '../../Components/Filter/IdeasBlock2';
import { saved, saving, couldnotSave, resetStatus } from '../../redux/saveSlice';
import { logChanges } from '../../Services/commentService';

const FilterComp = () => {
  const dispatch = useDispatch();
  const [topFiveIdeaList, setTopFiveIdeaList] = useState([]);
  const [considerableIdeaList, setConsiderableIdeaList] = useState([]);
  const [notRightIdeaList, setNotRightIdeaList] = useState([]);
  const [combinedData, setCombinedData] = useState(null);
  const [allGradesLoaded, setAllGradesLoaded] = useState(false);
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  useEffect(() => {
    if (userWorkspace) {
      getIdeaList();
    }
  }, [userWorkspace]);

  useEffect(() => {
    if (combinedData) {
      getIdeaGrades();
    }
  }, [combinedData]);

  useEffect(() => {
    if (combinedData && !allGradesLoaded) {
      const intervalId = setInterval(() => {
        getIdeaGrades();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [combinedData, allGradesLoaded]);

  const getIdeaGrades = async () => {
    const response = await listIdeaGrade();
    response.forEach((grade) => {
      if (
        grade?.overallGrade !== null &&
        grade?.grade1 !== null &&
        grade?.grade2 !== null &&
        grade?.grade3 !== null &&
        grade?.grade4 !== null &&
        grade?.grade5 !== null
      ) {
        setAllGradesLoaded(true);
      }
    });
    appendGradesToListData(response);
  };
  function appendGradesToListData(gradeList) {
    let top5List = topFiveIdeaList;
    let consider = considerableIdeaList;
    let notQuiteRight = notRightIdeaList;
    const arraysToUpdate = [top5List, consider, notQuiteRight];
    updateGrades(gradeList, arraysToUpdate);
  }

  const updateGrades = (gradeList, arraysToUpdate) => {
    gradeList.forEach((itemA) => {
      arraysToUpdate.forEach((array) => {
        if (array.length === 0) {
          return;
        }
        array.forEach((itemB) => {
          if (itemB?.id === itemA.id) {
            itemB.overallGrade = itemA.overallGrade;
            itemB.grade1 = itemA.grade1;
            itemB.grade2 = itemA.grade2;
            itemB.grade3 = itemA.grade3;
            itemB.grade4 = itemA.grade4;
            itemB.grade5 = itemA.grade5;
          }
        });
      });
    });
    setTopFiveIdeaList(arraysToUpdate[0]);
    setConsiderableIdeaList(arraysToUpdate[1]);
    setNotRightIdeaList(arraysToUpdate[2]);
  };
  const getIdeaList = async () => {
    try {
      let payload = {
        workspace: +userWorkspace.id,
      };
      const response = await listIdeaService(payload);
      if (response) {
        setTopFiveIdeaList(response?.top5);
        setConsiderableIdeaList(response?.considering);
        setNotRightIdeaList(response?.notQuiteRight);
        setCombinedData([
          response?.top5,
          response?.considering,
          response?.notQuiteRight,
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const rateIdea = async (ideaId, ideaRating, filterBlock) => {
    try {
      let payload = {
        ideaId: ideaId,
        rating: ideaRating,
      };
      dispatch(saving());
      const response = await ideaRatingService(payload, dispatch);
      if (response) {
        dispatch(saved());
        switch (filterBlock) {
          case 'topFive':
            setTopFiveIdeaList((prevState) => {
              let topFiveIdeaData = [...prevState];
              topFiveIdeaData.forEach((idea) => {
                if (idea?.id === ideaId) {
                  idea.rating = ideaRating;
                }
              });
              return [...topFiveIdeaData];
            });

            break;
          case 'Considering':
            setConsiderableIdeaList((prevState) => {
              let considerableIdeaData = prevState;
              considerableIdeaData.forEach((idea) => {
                if (idea?.id === ideaId) {
                  idea.rating = ideaRating;
                }
              });
              return [...considerableIdeaData];
            });
            break;
          case 'notQuiteRight':
            setNotRightIdeaList((prevState) => {
              let notRightIdeaData = [...prevState];
              notRightIdeaData.forEach((idea) => {
                if (idea?.id === ideaId) {
                  idea.rating = ideaRating;
                }
              });
              return [...notRightIdeaData];
            });
            break;
          default:
            break;
        }
        const matchingIdea = combinedData.flat().find(idea => idea.id === ideaId)
        let changeLogPayload = {
          changeDetail: `Idea '${matchingIdea.name}' updated`,
          workspaceId: userWorkspace.id
        }
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const handleIdeaDrag = (result) => {
    let combinedIdeaDataSet = {
      topFive: [...topFiveIdeaList],
      considering: [...considerableIdeaList],
      notQuiteRight: [...notRightIdeaList],
    };
    //If dropping destination is topFive ideas table
    if (result?.destination?.droppableId === 'topFive') {
      // If topFive ideas table already has five ideas in it.
      if (combinedIdeaDataSet.topFive.length === 5) {
        //Picking last idea item from topFive ideas table.
        const [itemToPush] = combinedIdeaDataSet.topFive.splice(4, 1);
        //Setting up the idea which needs to be pushed in topFive ideas table.
        const [itemToInsert] = combinedIdeaDataSet[
          result?.source?.droppableId
        ].splice(result?.source?.index, 1);
        //Inserting the idea which needs to be pushed in topFive.
        combinedIdeaDataSet.topFive.splice(
          result?.destination?.index,
          0,
          itemToInsert
        );
        //Moving the idea in 'itemToPush' to the considering table at the top level.
        combinedIdeaDataSet.considering.splice(0, 0, itemToPush);
      }
      //If topFive ideas table length is not five.
      else {
        const [reorderedItem] = combinedIdeaDataSet[
          result?.source?.droppableId
        ].splice(result?.source?.index, 1);
        combinedIdeaDataSet.topFive.splice(
          result?.destination?.index,
          0,
          reorderedItem
        );
      }
    }
    //Else do normal moving of ideas.
    else {
      const [reorderedItem] = combinedIdeaDataSet[
        result?.source?.droppableId
      ].splice(result?.source?.index, 1);
      combinedIdeaDataSet[result?.destination?.droppableId].splice(
        result?.destination?.index,
        0,
        reorderedItem
      );
    }
    updateIdeasRank(combinedIdeaDataSet);
  };

  const updateIdeasRank = (combinedIdeaList) => {
    for (const key in combinedIdeaList) {
      combinedIdeaList[key]?.forEach((ideaItem, index) => {
        if (ideaItem) {
          ideaItem.topidea = index + 1;
        }
      });
    }
    setTopFiveIdeaList(combinedIdeaList.topFive);
    setConsiderableIdeaList(combinedIdeaList.considering);
    setNotRightIdeaList(combinedIdeaList.notQuiteRight);
    saveIdeasList(combinedIdeaList);
  };
  const craftPayload = (payload) => {
    let payloadArray = [];
    for (const key in payload) {
      let ideaObject = {};
      if (key === 'topFive') {
        ideaObject['top5'] = [];
        payload[key]?.forEach((ideaItem) => {
          if (ideaItem) {
            let ideaDtl = {
              id: ideaItem?.id,
              topidea: ideaItem?.topidea,
              ideacategory: 'top5',
            };
            ideaObject['top5'].push(ideaDtl);
          }
        });
        payloadArray.push(ideaObject);
      }
      if (key === 'considering') {
        ideaObject['considering'] = [];
        payload[key]?.forEach((ideaItem) => {
          if (ideaItem) {
            let ideaDtl = {
              id: ideaItem?.id,
              topidea: ideaItem?.topidea,
              ideacategory: 'considering',
            };
            ideaObject['considering'].push(ideaDtl);
          }
        });
        payloadArray.push(ideaObject);
      }
      if (key === 'notQuiteRight') {
        ideaObject['notquiteright'] = [];
        payload[key]?.forEach((ideaItem) => {
          if (ideaItem) {
            let ideaDtl = {
              id: ideaItem?.id,
              topidea: ideaItem?.topidea,
              ideacategory: 'notright',
            };
            ideaObject['notquiteright'].push(ideaDtl);
          }
        });
        payloadArray.push(ideaObject);
      }
    }
    return payloadArray;
  };
  const saveIdeasList = async (ideaList) => {
    try {
      let rankData = craftPayload(ideaList);
      let payload = {
        workspace: +userWorkspace?.id,
        updatedRanks: JSON.stringify(JSON.stringify(rankData)),
      };
      const response = await addTopIdeaService(payload);
      if (response) {
        getIdeaList();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box mt={2} ml={2} mr={2} pb={3}>
      <Typography ml={2} component={'h1'} variant="dashboardCompHeading">
        {labels.filter.title}
      </Typography>
      <Box className="mt1">
        <IdeasBlock2
          topFiveIdeasData={
            topFiveIdeaList.sort((a, b) => a?.topidea - b?.topidea) || []
          }
          considerableIdeasData={
            considerableIdeaList.sort((a, b) => a?.topidea - b?.topidea) || []
          }
          notQuiteRightIdeasData={
            notRightIdeaList.sort((a, b) => a?.topidea - b?.topidea) || []
          }
          rateIdeaHandler={rateIdea}
          handleDragChange={handleIdeaDrag}
          isGuest={
            userWorkspace?.role === 'guest'
              ? userWorkspace?.workspacePrivelege === 'commenter'
                ? true
                : false
              : false
          }
        />
      </Box>
    </Box>
  );
};

export default FilterComp;
