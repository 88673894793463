import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { labels } from "../../data/constants";
import { deleteWorkspace, listWorkspace } from "../../Services/WorkspaceService";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWorkspace } from "../../redux/login";
import { setJobCenterData } from "../../redux/jobCenterSlice";

const DeleteWorkspace = ({ open, setOpen, userWorkspace }) => {
  const allWorkspaces = useSelector(
    (state) => state?.loginUserData?.user?.allWorkspaces
  )
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setOpen(false);
  const handleDeleteWorkspace = async () => {
    try {
      const payload = {
        workspaceId: userWorkspace?.id
      }
      await deleteWorkspace(payload);
      await listWorkspace(dispatch);
      const item = allWorkspaces.find((workspace) => workspace.isdefaultworkspace);
      dispatch(setSelectedWorkspace(item));
      dispatch(setJobCenterData(null));
      handleClose();
    }
    catch (e) {
      console.log(e)
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box mb={2}>
          <Typography variant="dashboardCompHeading2">
            {labels.workspace.deleteWorkspacePopupHeading}
          </Typography>
        </Box>
        <Typography>{labels.workspace.deleteConfirmLabel}</Typography>
        <Box mt={2} display="flex" justifyContent="space-between" gap={2}>
          <Button
            variant="outlined"
            sx={{
              color: "rgb(25, 62, 135)",
              borderColor: "rgb(25, 62, 135)",
              "&:hover": {
                borderColor: "rgb(25, 62, 135)",
                backgroundColor: "rgb(46 47 255 / 4%)",
              },
            }}
            onClick={handleClose}
          >
            {labels.workspace.cancelButtonLabel}
          </Button>
          <Button variant="warningButton" onClick={handleDeleteWorkspace}>
            {labels.workspace.deleteButtonLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteWorkspace;
