import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import styles from './Navigation.module.css';
import { useEffect, useState } from 'react';
import {
  getGuestWorkspacePrivelegesService,
  listWorkspace,
} from '../../../Services/WorkspaceService';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedWorkspace } from '../../../redux/login';
import { setMarketAndSubCats, setResearchListForSelectedIdea, setSelectedIdea, setSelectedMarketSubCatsForResearch } from "../../../redux/researchSlice";
import AddIcon from '@mui/icons-material/Add';
import CreateWorkspace from '../../Workspace/CreateWorkspace';
import { useLocation, useNavigate } from 'react-router-dom';
import { labels, proModalsContent } from "../../../data/constants";
import ProRequiredModal from '../../CommonComponents/proRequiredModal/proRequiredModal';
import { setJobCenterData } from '../../../redux/jobCenterSlice';

const WorkspaceList = () => {
  const dispatch = useDispatch();
  const [ownerWorkspaces, setOwnerWorkspaces] = useState([]);
  const [guestWorkspaces, setGuestWorkspaces] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [proRequiredModalShow, setProRequiredModalShow] = useState(false);
  const currentUserWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const allWorkspaces = useSelector(
    (state) => state?.loginUserData?.user?.allWorkspaces
  );
  const isProAccount = useSelector((state) => state?.loginUserData?.user?.userDetails?.proAccountDetails?.isProAccount);
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const wrapStyle = {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  };
  useEffect(() => {
    if (allWorkspaces) {
      const sortedWorkspaces = [...allWorkspaces].sort(
        (a, b) => b.isdefaultworkspace - a.isdefaultworkspace
      );
      let ownerWorkspaceList = [],
        guestWorkspaceList = [];
      sortedWorkspaces.forEach((workspaceOption) => {
        if (workspaceOption?.role === 'owner') {
          ownerWorkspaceList.push(workspaceOption);
        } else {
          guestWorkspaceList.push(workspaceOption);
        }
      });
      setOwnerWorkspaces(ownerWorkspaceList);
      setGuestWorkspaces(guestWorkspaceList);
    }
  }, [allWorkspaces]);
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    try {
      await listWorkspace(dispatch);
    } catch (error) {
      console.error(error);
    }
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (item) => {
    handleClose();
    let guestUserPrivileges;
    if (item?.role === 'guest') {
      guestUserPrivileges = await fetchGuestUserPrivileges(item?.id);
    }
    const payload = {
      id: item?.id,
      name: item?.name,
      isdefaultworkspace: item?.isdefaultworkspace,
      status: item?.status,
      workspacePrivelege: guestUserPrivileges?.role,
      hideProfile: guestUserPrivileges?.hideprofile,
      role: item?.role,
    };
    localStorage.setItem('workspace', JSON.stringify(payload));
    dispatch(setSelectedWorkspace(payload));
    dispatch(setJobCenterData(null));
    dispatch(setSelectedIdea(null));
    dispatch(setMarketAndSubCats(null));
    dispatch(setSelectedMarketSubCatsForResearch(null));
    dispatch(setResearchListForSelectedIdea(null));
    if (currentPath.includes('/invite')) {
      navigate('/dashboard');
    }
  };
  const onCreateWorkspace = () => {
    handleClose();
    if (isProAccount) {
      setCreateModalOpen(true);
    } else {
      setProRequiredModalShow(true);
    }
  };

  const fetchGuestUserPrivileges = async (guestWorkspaceId) => {
    try {
      const payload = {
        workspaceId: guestWorkspaceId,
      };
      const response = await getGuestWorkspacePrivelegesService(payload);
      if (response) {
        return response;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Button
        className={`${styles.sidebarMenu} fontSize14 fontWeight600 textTransformNone`}
        endIcon={<UnfoldMoreIcon />}
        id="menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {currentUserWorkspace?.name}
      </Button>
      <Menu
        sx={{ width: '100%' }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Typography className="fontSize12 fontWeight700 ml1">
          OWNER OF
        </Typography>
        {ownerWorkspaces?.map((item, index) => (
          <MenuItem
            sx={wrapStyle}
            key={index}
            onClick={() => {
              handleMenuItemClick(item);
            }}
          >
            <Box className="displayFlex alignItemsCenter">
              <Typography
                variant="customStyle3"
                sx={{
                  borderRadius: '20px',
                  height: '35px',
                  aspectRatio: '1',
                  marginRight: '8px',
                  marginBottom: '0px',
                }}
              >
                {item?.name[0]}
              </Typography>
              <Typography className="fontSize14 fontWeight600">
                {item?.name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
        {guestWorkspaces.length !== 0 && (
          <Box>
            <Divider />
            <Typography className="fontSize12 fontWeight700 ml1">
              GUEST IN
            </Typography>
            {guestWorkspaces?.map((item, index) => (
              <MenuItem
                sx={wrapStyle}
                key={index}
                onClick={() => {
                  handleMenuItemClick(item);
                }}
              >
                <Box className="displayFlex alignItemsCenter">
                  <Typography
                    variant="customStyle3"
                    sx={{
                      borderRadius: '20px',
                      height: '35px',
                      aspectRatio: '1',
                      marginRight: '8px',
                      marginBottom: '0px',
                      color: 'rgb(245, 194, 66)',
                      backgroundColor: 'rgb(253 245 225)',
                    }}
                  >
                    {item?.name[0]}
                  </Typography>
                  <Typography className="fontSize14 fontWeight600">
                    {item?.name}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Box>
        )}
        <Divider />
        <MenuItem sx={wrapStyle} onClick={onCreateWorkspace}>
          <AddIcon fontSize="small" sx={{ marginRight: '8px' }}></AddIcon>{' '}
          {labels.workspace.createButtonLabel}
        </MenuItem>
      </Menu>
      <CreateWorkspace
        open={createModalOpen}
        setOpen={setCreateModalOpen}
      ></CreateWorkspace>
      <ProRequiredModal
        open={proRequiredModalShow}
        setOpen={setProRequiredModalShow}
        content={proModalsContent.createWorkspace}
      ></ProRequiredModal>
    </>
  );
};
export default WorkspaceList;
