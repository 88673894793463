import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import {
  editCollaborator,
  reInviteCollaborator,
} from "../../Services/WorkspaceService";
import LoopIcon from "@mui/icons-material/Loop";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { saved, saving } from "../../redux/saveSlice";
import { logChanges } from "../../Services/commentService";

const ExistingCollaborator = ({
  collaborator,
  onDeleteCollaborator,
  isGuest,
  userEmail,
}) => {
  const dispatch = useDispatch();
  const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  }));
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(null);
  const [isProfileHide, setIsProfileHide] = useState(collaborator?.hideprofile);
  const [updateRole, setUpdateRole] = useState(collaborator?.role);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const openDeleteConfirm = () => {
    setIsDeleteConfirmOpen(true);
  };
  const deleteConfirm = async () => {
    await onDeleteCollaborator(collaborator?.id);
    setIsDeleteConfirmOpen(false);
  };
  const handleProfileHideChange = async (event) => {
    setIsProfileHide(event.target.checked);
    const payload = {
      inviteeEmail: collaborator.email,
      role: updateRole,
      hideProfile: event.target.checked,
    };
    await editCollaborator(payload, dispatch);
  };

  const handleMenuItemClick = async (role) => {
    handleClose();
    if (role === updateRole) {
      return;
    }
    setUpdateRole(role);
    const payload = {
      inviteeEmail: collaborator.email,
      role: role,
      hideProfile: isProfileHide,
    };
    await editCollaborator(payload, dispatch);
  };

  const handleReInvite = async () => {
    const payload = {
      inviteId: collaborator?.id,
    };
    dispatch(saving());
    await reInviteCollaborator(payload, dispatch);
    dispatch(saved());
    let changeLogPayload = {
      changeDetail: `Re-Invited Collaborator: ${collaborator.email}`,
      workspaceId: userWorkspace.id
    }
    await logChanges(changeLogPayload, dispatch);
  };
  return (
    <>
      {/* collaborators profile */}
      <Box className="displayFlex justifySpaceBetween alignItemsCenter">
        <Box className="displayFlex alignItemsCenter">
          <AccountCircleTwoToneIcon
            fontSize="large"
            className="mr1"
            sx={{
              color: `${collaborator?.isPending
                  ? "rgb(245, 194, 66)"
                  : "rgb(121, 209, 151)"
                }`,
            }}
          />
          <Box>
            {!collaborator?.isPending && (
              <>
                {userEmail === collaborator?.email ? (
                  <Typography variant="tableHeading">Me</Typography>
                ) : (
                  <Typography variant="tableHeading">
                    {collaborator?.invitee?.first_name}{" "}
                    {collaborator?.invitee?.last_name}
                  </Typography>
                )}
              </>
            )}
            <Typography>{collaborator?.email}</Typography>
            {collaborator?.isPending && (
              <Typography
                className="fontSize14"
                sx={{ color: "rgb(203, 148, 11)" }}
              >
                Invite Pending
              </Typography>
            )}
          </Box>
        </Box>
        <Box className="displayFlex alignItemsCenter" gap={2}>
          {!isDeleteConfirmOpen && collaborator?.isPending && (
            <Box>
              <IconButton
                variant="purpleBtnLight"
                disabled={isGuest}
                onClick={handleReInvite}
              >
                <LoopIcon fontSize="small"></LoopIcon>Re-Invite
              </IconButton>
            </Box>
          )}
          {!isDeleteConfirmOpen && (
            <Box
              gap={1}
              className="displayFlex justifySpaceBetween alignItemsCenter"
            >
              <Box>
                <Button
                  variant="menuButton"
                  endIcon={<UnfoldMoreIcon />}
                  id="menu-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="textTrasformCapital"
                  disabled={isGuest}
                >
                  {updateRole}
                </Button>
                <Menu
                  sx={{ width: "100%" }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    selected={updateRole === "commenter"}
                    onClick={() => {
                      handleMenuItemClick("commenter");
                    }}
                  >
                    <Box className="displayFlex alignItemsCenter">
                      <Typography className="fontSize14 fontWeight600">
                        Commenter
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    selected={updateRole === "editor"}
                    onClick={() => {
                      handleMenuItemClick("editor");
                    }}
                  >
                    <Box className="displayFlex alignItemsCenter">
                      <Typography className="fontSize14 fontWeight600">
                        Editor
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
              <Box>
                <FormControlLabel
                  className="mx0px"
                  control={
                    <SmallCheckbox
                      defaultChecked
                      disabled={isGuest}
                      checked={isProfileHide}
                    />
                  }
                  onChange={handleProfileHideChange}
                  label={
                    <span className="fontSize12 displayBlock mx0px">
                      Hide my profile
                    </span>
                  }
                />
              </Box>
              <Box>
                <IconButton
                  disabled={isGuest}
                  onClick={openDeleteConfirm}
                  sx={{ color: "red" }}
                >
                  <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
        {isDeleteConfirmOpen && (
          <Box
            gap={1}
            className="displayFlex justifySpaceBetween alignItemsCenter"
          >
            <Typography className="fontSize12">
              Are you sure you want to remove this collaborator?
            </Typography>
            <Button
              sx={{
                height: "26px",
                fontSize: "12px",
              }}
              size="small"
              variant="purpleBtnLight"
              onClick={() => {
                setIsDeleteConfirmOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "auto",
                height: "26px",
                fontSize: "12px",
              }}
              size="small"
              variant="purpleBtnDark"
              onClick={() => {
                deleteConfirm();
              }}
            >
              Remove
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
export default ExistingCollaborator;
