import { Box, Typography, Button, Grid } from '@mui/material';
import { labels, proPackDetails } from '../../data/constants';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { saving, saved, couldnotSave, resetStatus } from '../../redux/saveSlice';
import { makePayment } from '../../Services/PaymentServices';
import { useEffect } from 'react';
import { getUserDetailService } from '../../Services/AccountServices';

const ProPlanContainer = () => {
  const dispatch = useDispatch();

  const userPlanDetails = useSelector(
    (state) => state?.loginUserData?.user?.planDetails
  );

  useEffect(() => {
    getUserDetailService(dispatch);
  }, []);
  const handlePurchase = async (planId) => {
    try {
      let payload = {
        planId: planId
      };
      console.log(payload);
      dispatch(saving());
      const response = await makePayment(payload, dispatch);
      if (response) {
        dispatch(saved());
        console.log(response);
        window.open(response?.data?.url, '_self');
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  }

  return (
    <Box className='mt2 threeColumnLayout30'>
      <Box className='padding1 border1 borderColorGrey borderRadius8'>
        <Typography
          component='h3'
          className='minHeight70'
          variant="dashboardCompHeading3"
          mr={2}
        >
          {proPackDetails.pro50Pack.pro50Name} {' - '}{proPackDetails.pro50Pack.pro50Price}
        </Typography>
        <Box className='minHeight400'>
          {proPackDetails.pro50Pack.pro50Details.map((item, index) => {
            // Check if the item contains the specific detail that needs JSX rendering
            if (typeof item === 'string' && item.includes('ProLabel')) {
              return (
                <Box key={index} className='displayFlex' mb={1}>
                  <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green' }} />
                  <Typography className='ml05'>
                    <b>50</b>
                    <Typography className="ml05" variant="customStyle2">
                      {labels.ideaDetails.pro}
                    </Typography>
                    {' '}
                    credits per month
                  </Typography>
                </Box>
              );
            }
            return (
              <Box key={index} className='displayFlex' mb={1}>
                <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green' }} />
                <Typography
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="ml05"
                />
              </Box>
            );
          })}
        </Box>
        <Button
          className="textTransformNone"
          variant="purpleBtnDark"
          onClick={() => handlePurchase(proPackDetails.pro50Pack.planId)}
          disabled={(userPlanDetails?.proAccountDetails?.purchased_plan_id == 1 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 2 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 3) ? true : false}
          sx={(userPlanDetails?.proAccountDetails?.purchased_plan_id == 1 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 2 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 3) ? { backgroundColor: '#e8e1fa' } : null}
        >
          {proPackDetails.pro50Pack.pro50UpgradeBtn}
        </Button>
      </Box>
      <Box className='padding1 border1 borderColorGrey borderRadius8'>
        <Typography
          component='h3'
          className='minHeight70'
          variant="dashboardCompHeading3"
          mr={2}
        >
          {proPackDetails.proUnlimitedPack.proUnlimitedName} {' - '}{proPackDetails.proUnlimitedPack.proUnlimitedPrice}
        </Typography>
        <Box className='minHeight400'>
          {proPackDetails.proUnlimitedPack.proUnlimitedDetails.map((item, index) => {
            // Check if the item contains the specific detail that needs JSX rendering
            if (typeof item === 'string' && item.includes('ProLabel')) {
              return (
                <Box key={index} className='displayFlex' mb={1}>
                  <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green' }} />
                  <Typography className='ml05'>
                    <b>Unlimited</b>
                    <Typography className="ml05" variant="customStyle2">
                      {labels.ideaDetails.pro}
                    </Typography>
                    {' '}
                    credits
                  </Typography>
                </Box>
              );
            }
            return (
              <Box key={index} className='displayFlex' mb={1}>
                <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green' }} />
                <Typography
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="ml05"
                />
              </Box>
            );
          })}
        </Box>
        <Button
          className="textTransformNone"
          variant="purpleBtnDark"
          onClick={() => handlePurchase(proPackDetails.proUnlimitedPack.planId)}
          disabled={(userPlanDetails?.proAccountDetails?.purchased_plan_id == 1 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 2 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 3) ? true : false}
          sx={(userPlanDetails?.proAccountDetails?.purchased_plan_id == 1 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 2 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 3) ? { backgroundColor: '#e8e1fa' } : null}
        >
          {proPackDetails.proUnlimitedPack.proUnlimitedUpggradeBtn}
        </Button>
      </Box>
      <Box className='padding1 border1 borderColorGrey borderRadius8'>
        <Typography
          component='h3'
          className='minHeight70'
          variant="dashboardCompHeading3"
          mr={2}
        >
          {proPackDetails.proAnnualPack.proAnnualName}<br />
          <Typography variant='dashboardCompHeading2'>
            <span className='m0 lineThrough'>{proPackDetails.proAnnualPack.proAnnualOldPrice}</span>
            {' '}
            {proPackDetails.proAnnualPack.proAnnualPrice}
          </Typography>
          <Typography variant="badgeText2">
            {proPackDetails.proAnnualPack.annualDiscount}
          </Typography>
        </Typography>
        <Box className='minHeight400'>
          {proPackDetails.proAnnualPack.proAnnualDetails.map((item, index) => {
            // Check if the item contains the specific detail that needs JSX rendering
            if (typeof item === 'string' && item.includes('ProLabel')) {
              return (
                <Box key={index} className='displayFlex' mb={1}>
                  <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green' }} />
                  <Typography className='ml05'>
                    <b>Unlimited</b>
                    <Typography className="ml05" variant="customStyle2">
                      {labels.ideaDetails.pro}
                    </Typography>
                    {' '}
                    credits
                  </Typography>
                </Box>
              );
            }
            return (
              <Box key={index} className='displayFlex' mb={1}>
                <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green' }} />
                <Typography
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="ml05"
                />
              </Box>
            );
          })}
        </Box>
        <Button
          className="textTransformNone"
          variant="purpleBtnDark"
          onClick={() => handlePurchase(proPackDetails.proAnnualPack.planId)}
          disabled={(userPlanDetails?.proAccountDetails?.purchased_plan_id == 1 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 2 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 3) ? true : false}
          sx={(userPlanDetails?.proAccountDetails?.purchased_plan_id == 1 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 2 || userPlanDetails?.proAccountDetails?.purchased_plan_id == 3) ? { backgroundColor: '#e8e1fa' } : null}
        >
          {proPackDetails.proAnnualPack.proAnnualUpgradeBtn}
        </Button>
      </Box>
    </Box>
  );
};

export default ProPlanContainer;
