import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedIdea: null,
  selectedMarketOption: null,
  assessmentList: null,
  competitorList: null,
  selectedCompetitor: null,
  researchIOngoing: false,
  ideasList: null,
  marketAndSubCats: [],
  marketsList: null,
  researchListForSelectedIdea: null,
  selectedMarketSubCatsForResearch: null,
};

const researchSlice = createSlice({
  name: 'researchSlice',
  initialState,
  reducers: {
    setSelectedIdea: (state, action) => {
      state.selectedIdea = action.payload;
    },
    setSelectedMarketOption: (state, action) => {
      state.selectedMarketOption = action.payload;
    },
    setAssessmentListOptions: (state, action) => {
      state.assessmentList = action.payload;
    },
    setCompetitorListOption: (state, action) => {
      state.competitorList = action.payload;
    },
    setSelectedCompetitor: (state, action) => {
      state.selectedCompetitor = action.payload;
    },
    setActiveResearch: (state, action) => {
      state.researchIOngoing = action.payload;
    },
    setIdeasList: (state, action) => {
      state.ideasList = action.payload;
    },
    setMarketAndSubCats: (state, action) => {
      state.marketAndSubCats = action.payload;
    },
    setMarketsList: (state, action) => {
      state.marketsList = action.payload;
    },
    setResearchListForSelectedIdea: (state, action) => {
      state.researchListForSelectedIdea = action.payload;
    },
    setSelectedMarketSubCatsForResearch: (state, action) => {
      state.selectedMarketSubCatsForResearch = action.payload;
    },
  },
});

export const {
  setSelectedIdea,
  setAssessmentListOptions,
  setSelectedMarketOption,
  setCompetitorListOption,
  setSelectedCompetitor,
  setActiveResearch,
  setIdeasList,
  setMarketAndSubCats,
  setMarketsList,
  setResearchListForSelectedIdea,
  setSelectedMarketSubCatsForResearch,
} = researchSlice.actions;

export default researchSlice.reducer;
