import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Tooltip,
  TextField,
} from '@mui/material';
import MultiSelectChip from '../Custom Components/MultiSelectChip/MultiSelectChip';
import { Info } from '@mui/icons-material';
const StudentsTabBlock = ({
  blockDetail,
  handleAnswerSubmit = () => {},
  dataHandler = () => {},
}) => {
  return (
    <Box mt={2} ml={2}>
      <Grid container columnSpacing={2}>
        {blockDetail?.map((blockOption, index) => {
          if (blockOption.status === true) {
            return (
              <Grid key={'blockOption' + index} item mb={2} xs={12} lg={6}>
                <InputLabel
                  sx={{ overflow: 'visible' }}
                  htmlFor={blockOption?.title}
                  shrink={false}
                  className="dashboardLabel1"
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    {blockOption?.question}
                    {blockOption?.toolTip && (
                      <Tooltip title={blockOption?.toolTip}>
                        <Info fontSize="small" sx={{ marginLeft: 1 }} />
                      </Tooltip>
                    )}
                  </Typography>
                </InputLabel>
                {blockOption?.type === 'textField' || blockOption?.type === 'textFieldMulti' ? (
                  <TextField
                    sx={{ marginTop: 2 }}
                    id={blockOption?.title}
                    placeholder={blockOption?.placeholder}
                    className="textFieldType2"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={blockOption?.answer || ''}
                    onChange={(e) => {
                      dataHandler(blockOption?.id, e.target.value);
                    }}
                    onBlur={() => {
                      handleAnswerSubmit(blockOption?.id, blockOption?.answer);
                    }}
                  />
                ) : blockOption?.type === 'multiSelect' ? (
                  <MultiSelectChip
                    allowMulti={true}
                    labelId={blockOption?.title}
                    selectId={blockOption?.title}
                    selectedValue={blockOption?.answer || []}
                    dropDownItems={blockOption?.options}
                    handleChange={(value) => {
                      dataHandler(blockOption?.id, value);
                    }}
                    handleBlur={() => {
                      handleAnswerSubmit(blockOption?.id, blockOption?.answer);
                    }}
                  />
                ) : blockOption?.type === 'select' ? (
                  <MultiSelectChip
                    allowMulti={false}
                    labelId={blockOption?.title}
                    selectId={blockOption?.title}
                    selectedValue={blockOption?.answer || []}
                    dropDownItems={blockOption?.options}
                    handleChange={(value) => {
                      dataHandler(blockOption?.id, value);
                    }}
                    handleBlur={() => {
                      handleAnswerSubmit(
                        blockOption?.id,
                        blockOption?.answer?.value
                      );
                    }}
                  />
                ) : (
                  ''
                )}
              </Grid>
            );
          }
        })}
      </Grid>
    </Box>
  );
};

export default StudentsTabBlock;
