import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { labels } from "../../data/constants";
import { useState } from "react";
import { createWorkspace, listWorkspace } from "../../Services/WorkspaceService";
import { useDispatch } from "react-redux";
import { logChanges } from "../../Services/commentService";
import { saving, saved, couldnotSave, resetStatus } from "../../redux/saveSlice";

const CreateWorkspace = ({ open, setOpen }) => {
  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const handleClose = () => {
    setInputValue('');
    setOpen(false)
  };
  const handleInputChange = (e) => {
    if (e.target.value?.length > 20) {
      return;
    }
    setInputValue(e.target.value);
  };
  const handleCreateWorkspace = async () => {
    try {
      const payload = {
        workspaceName: inputValue,
      };
      dispatch(saving());
      const createResp = await createWorkspace(payload, dispatch);
      if (createResp) {
        dispatch(saved());
        handleClose();
        await listWorkspace(dispatch);
        let changeLogPayload = {
          changeDetail: `Workspace '${inputValue}' created`,
          workspaceId: createResp?.id
        }
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box mb={2}>
          <Typography variant="dashboardCompHeading2">
            {labels.workspace.createButtonLabel}
          </Typography>
        </Box>
        <InputLabel required className="dashboardLabel1 fontWeight500">
          {labels.workspace.workspaceNameInputLabel}
        </InputLabel>
        <TextField
          fullWidth
          className="textFieldType3"
          placeholder={labels.businessIdeas.addIdeaModal.namePlaceholder}
          value={inputValue}
          onChange={handleInputChange}
        />
        <Box mt={2} display="flex" justifyContent="space-between" gap={2}>
          <Button
            variant="outlined"
            sx={{
              color: "rgb(25, 62, 135)",
              borderColor: "rgb(25, 62, 135)",
              "&:hover": {
                borderColor: "rgb(25, 62, 135)",
                backgroundColor: "rgb(46 47 255 / 4%)",
              },
            }}
            onClick={handleClose}
            className="textTransformNone"
          >
            {labels.workspace.cancelButtonLabel}
          </Button>
          <Button variant="buttonVariant1" className="textTransformNone" onClick={handleCreateWorkspace}>
            {labels.workspace.createButtonLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateWorkspace;
