import { Box, Typography } from '@mui/material';
import ProfileTabBlock from './ProfileTabBlock';
import CustomCircularProgress from '../Custom Components/CustomCircularProgress/CustomCircularProgress';
const ProfileTabContent = ({
  tabContent,
  submitAnswer = () => { },
  tabDataHandler = () => { },
}) => {
  return (
    <Box mt={3} ml={2} sx={{ borderBottom: 1, borderColor: 'divider' }} pb={3}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography
          component={'h3'}
          variant="dashboardCompHeading"
          className="fontSize26"
          mr={2}
        >
          {tabContent?.tabName}
        </Typography>
        {tabContent?.completionPercentage !== undefined ? (
          <CustomCircularProgress
            value={tabContent?.completionPercentage || 0}
          />
        ) : (
          ''
        )}
      </Box>
      <ProfileTabBlock
        handleAnswerSubmit={submitAnswer}
        blockDetail={[...(tabContent?.questions || [])].sort((a, b) => a?.id - b?.id)}
        dataHandler={tabDataHandler}
      />
    </Box>
  );
};

export default ProfileTabContent;
