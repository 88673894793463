import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  acceptCollaboratorWorkspace,
  getGuestWorkspacePrivelegesService,
  listWorkspace,
} from "../../Services/WorkspaceService";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWorkspace } from "../../redux/login";
import { logChanges } from "../../Services/commentService";
import { saved, saving, couldnotSave, resetStatus } from "../../redux/saveSlice";
import { setJobCenterData } from "../../redux/jobCenterSlice";

const InvitationComp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, seterror] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const allWorkspaces = useSelector(
    (state) => state?.loginUserData?.user?.allWorkspaces
  );
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const workspaceId = queryParams.get("workspaceId");
    const payload = {
      inviteeEmail: email,
      workspaceId: +workspaceId,
    };
    acceptCollab(payload);
  }, [location.search]);
  const acceptCollab = async (payload) => {
    try {
      setLoading(true);
      dispatch(saving());
      const response = await acceptCollaboratorWorkspace(payload, dispatch);
      if (response) {
        dispatch(saved());
        setData(response?.data);
        seterror(null);
        await listWorkspace(dispatch);
        const invitedWorkspace = allWorkspaces?.find(workspace => workspace?.name === data)
        let changeLogPayload = {
          changeDetail: `User accepted a workspace collaborator invite`,
          workspaceId: invitedWorkspace?.id
        }
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (error) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      seterror(error);
    } finally {
      setLoading(false);
    }
  };
  const handleNavigateDashboard = () => {
    navigate("/dashboard");
  };
  const handleChangeWorkspace = async () => {
    let guestUserPrivileges;
    const invitedWorkspace = allWorkspaces?.find(workspace => workspace?.name === data)
    if (invitedWorkspace?.role === 'guest') {
      guestUserPrivileges = await fetchGuestUserPrivileges(invitedWorkspace?.id);
    }
    const payload = {
      id: invitedWorkspace?.id,
      name: invitedWorkspace?.name,
      isdefaultworkspace: invitedWorkspace?.isdefaultworkspace,
      status: invitedWorkspace?.status,
      role: invitedWorkspace?.role,
      workspacePrivelege: guestUserPrivileges?.role,
      hideProfile: guestUserPrivileges?.hideprofile,
    };
    localStorage.setItem('workspace', JSON.stringify(payload));
    dispatch(setSelectedWorkspace(payload));
    dispatch(setJobCenterData(null));
    handleNavigateDashboard();
  };
  const fetchGuestUserPrivileges = async (guestWorkspaceId) => {
    try {
      const payload = {
        workspaceId: guestWorkspaceId,
      };
      const response = await getGuestWorkspacePrivelegesService(payload);
      if (response) {
        return response;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box className="mt1 mr1 mb1 ml2">
      {loading ? (
        <CircularProgress size={"1rem"} />
      ) : error ? (
        <Alert severity="error">
          <AlertTitle>
            <Typography
              variant="dashboardCompHeading2"
              className="displayBlock"
              sx={{ color: "rgb(244, 67, 54)" }}
            >
              Unable to process invite
            </Typography>
          </AlertTitle>
          <Typography>
            Please try again. If you continue to have problems, please have the
            workspace owner re-send your invite.
          </Typography>
          <Button
            onClick={handleNavigateDashboard}
            className="mt5p"
            variant="purpleBtnDark"
            sx={{ width: "auto" }}
          >
            Go back to Dashboard
          </Button>
        </Alert>
      ) : (
        <Alert
          icon={<ArrowBackOutlinedIcon fontSize="inherit" />}
          severity="success"
        >
          <Typography
            variant="dashboardCompHeading2"
            className="displayBlock"
            sx={{ color: "#2e7d32" }}
          >
            Change Workspace
          </Typography>
          <Typography
            variant="dashboardCompHeading2"
            className="displayBlock mt05 mb05"
          >
            Invite Accepted
          </Typography>
          <Typography>
            You can now collaborate within the{" "}
            <span className="fontWeight700">{data}</span> workspace. You can
            navigate between workspaces with the dropdown at the top of the main
            menu, or simple click below.
          </Typography>
          <Button
            onClick={handleChangeWorkspace}
            className="mt5p"
            variant="purpleBtnDark"
            sx={{ width: "auto" }}
          >
            Change to {data}
          </Button>
        </Alert>
      )}
    </Box>
  );
};

export default InvitationComp;
