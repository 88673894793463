import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { labels } from '../../data/constants';
import ResearchTabComp from '../../Components/Research/ResearchTabComp';
import { useEffect, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import BusinessIcon from '@mui/icons-material/Business';
import IdeaTabContent from '../../Components/Research/IdeaTabContent';
import MarketTabContent from '../../Components/Research/Market/MarketTabContent';
import CompetitorTabContent from '../../Components/Research/Competitor/CompetitorTabContent';
import { useSelector } from 'react-redux';
import { getBusinessIdeasService } from '../../Services/businessIdeasService';
import {
  aiAutoResearchService,
  createCompetitorService,
  fetchMarketsWithSubcategoriesService,
  getAssessmentListService,
  getCompetitorListService,
  getMarketListService,
  updateMarketService,
  getResearchIdeaService,
  createMarketService,
} from '../../Services/ResearchServices';
import { useDispatch } from 'react-redux';
import { saved, saving, couldnotSave, resetStatus } from '../../redux/saveSlice';
import { logChanges } from '../../Services/commentService';
import {
  setAssessmentListOptions,
  setCompetitorListOption,
  setActiveResearch,
  setSelectedMarketOption,
  setResearchListForSelectedIdea,
} from '../../redux/researchSlice';
import AutoResearchModal from '../../Components/Research/AutoResearchModal';
import ActiveResearchPanel from '../../Components/Research/ActiveResearchPanel';
import { io } from 'socket.io-client';

const ResearchComp = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [assessmentList, setAssessmentList] = useState([]);
  const [showARModal, setShowARModal] = useState(false);
  const [showActiveResearchPanel, setShowActiveResearchPanel] = useState(false);
  const [competitorList, setCompetitorList] = useState([]);
  const [tabOptions] = useState([
    {
      tabName: 'Ideas',
      tabIcon: <DesignServicesIcon />,
    },
    {
      tabName: 'Markets',
      tabIcon: <LanguageIcon />,
    },
    {
      tabName: 'Competitors',
      tabIcon: <BusinessIcon />,
    },
  ]);
  const [aiSearchStatus, setAIResearchStatus] = useState({
    status: false,
    webpageSearched: null,
    pageAnalysed: null,
    competitorEvaluated: null,
    attributeConsidered: null,
    taskStatuses: {
      marketResearchStatus: false,
      subCatResearchStatus: false,
      competitorResearchStatus: false,
      compSubCatResearchStatus: false,
      compStatsResearchStatus: false,
      compAssessResearchStatus: false,
    },
  });
  const handleTabChange = (event, tabInfo) => {
    setSelectedTab(tabInfo);
  };
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const selectedIdea = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const activeUserId = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.id
  );
  const selectedMarketOption = useSelector(
    (state) => state?.researchData?.selectedMarketOption
  );
  const marketsList = useSelector(
    (state) => state?.researchData?.marketsList
  );
  const activeResearchStatus = useSelector(
    (state) => state?.researchData?.researchIOngoing
  );
  const researchListForSelectedIdea = useSelector(
    (state) => state?.researchData?.researchListForSelectedIdea
  );
  const socketUrl = process.env.REACT_APP_RESEARCH_WEB_SOCKET;

  useEffect(() => {
    fetchIdeaList();
    fetchCompetitorsList();
    fetchAssessmentsList();
    fetchMarketWithSubCategories();
    const payload = {
      workspaceId: userWorkspace?.id,
    };
    getMarketListService(payload, dispatch);
  }, [userWorkspace]);


  useEffect(() => {
    if (selectedIdea?.id) {
      fetchAndSetResearchDataForSelectedIdea();
    }
  }, [selectedIdea]);

  useEffect(() => {
    if (activeResearchStatus) {
      const socketConnection = io(socketUrl, {
        transports: ['websocket', 'polling'],
        withCredentials: true,
        reconnectionAttempts: 3,
      });
      socketConnection.on('connect', () => {
        socketConnection.emit('subscribeToResearch', activeUserId);
      });
      socketConnection.on('close', () => {
        // console.log('connection closed');
      });

      socketConnection.on('searchUpdate', (data) => {
        setAIResearchStatus((prevState) => {
          return {
            ...prevState,
            webpageSearched: data?.searchCount,
          };
        });
        // console.log('Received searchUpdate event:', data);
      });
      socketConnection.on('scrapeUpdate', (data) => {
        setAIResearchStatus((prevState) => {
          return {
            ...prevState,
            pageAnalysed: data?.pageCount,
          };
        });
        // console.log('Received scrapeUpdate event:', data);
      });
      setShowActiveResearchPanel(true);
    }
  }, [activeResearchStatus]);

  const fetchIdeaList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      await getBusinessIdeasService(payload, dispatch);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchAssessmentsList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }

      const response = await getAssessmentListService();
      if (response) {
        setAssessmentList(response);
        dispatch(setAssessmentListOptions(response));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchCompetitorsList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getCompetitorListService(payload);
      if (response) {
        let competitorList = [];

        response?.forEach((item) => {
          let competitor = {
            competitorName: item?.competitor_name,
            competitorId: item?.id,
          };
          competitorList.push(competitor);
        });
        setCompetitorList(competitorList);
        dispatch(setCompetitorListOption(competitorList));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchMarketsListAndSetSelectedMarket = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getMarketListService(payload, dispatch);
      marketsList?.map(market => {
        if (response?.researchIdeaDetails?.length) {
          if (market?.id === response?.researchIdeaDetails[0]?.market_id) {
            dispatch(setSelectedMarketOption(market));
          } else {
            dispatch(setSelectedMarketOption(null));
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const fetchAndSetResearchDataForSelectedIdea = async () => {
    try {
      const payload = {
        workspaceId: userWorkspace?.id,
        ideaId: selectedIdea?.id,
      };
      const response = await getResearchIdeaService(payload, dispatch);
      if (response?.researchIdeaDetails?.length) {
        const getMarketsPayload = {
          workspaceId: userWorkspace?.id,
        };
        await getMarketListService(getMarketsPayload, dispatch);
        marketsList?.map((market) => {
          if (market?.id === researchListForSelectedIdea[0]?.market_id) {
            dispatch(setSelectedMarketOption(market));
          } else {
            dispatch(setSelectedMarketOption(null));
          }
        });
        // fetchMarketsListAndSetSelectedMarket();
        fetchMarketWithSubCategories();
      } else {
        dispatch(setSelectedMarketOption(null));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const AddMarket = async (marketName) => {
    try {
      const payload = {
        workspaceId: userWorkspace?.id,
        marketName: marketName,
      };
      dispatch(saving());
      const response = await createMarketService(payload, dispatch);
      if (response) {
        dispatch(saved());
        fetchMarketsListAndSetSelectedMarket();
        fetchMarketWithSubCategories();
        let changeLogPayload = {
          changeDetail: `Market '${marketName}' created`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const AddCompetitor = async (competitorName) => {
    try {
      const payload = {
        workspaceId: +userWorkspace?.id,
        competitorName: competitorName,
      };
      dispatch(saving());
      const response = await createCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorName}' created`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const updateMarket = async (marketDetail) => {
    try {
      const payload = {
        workspaceId: userWorkspace?.id,
        marketName: marketDetail?.market_name,
        marketId: marketDetail?.id,
        cagr: marketDetail?.cagr || null,
        cagr_url: marketDetail?.cagr_url || null,
        tam: marketDetail?.tam || null,
        tam_url: marketDetail?.tam_url || null,
      };
      dispatch(saving());
      const response = await updateMarketService(payload, dispatch);
      if (response) {
        dispatch(saved());
        fetchMarketsListAndSetSelectedMarket();
        fetchMarketWithSubCategories();
        let changeLogPayload = {
          changeDetail: `Market '${marketDetail.market_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const fetchMarketWithSubCategories = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      let payload = {
        workspaceId: userWorkspace?.id,
      };
      await fetchMarketsWithSubcategoriesService(payload, dispatch);
    } catch (e) {
      console.log(e);
    }
  };
  const showAIAutoResearchModal = () => {
    if (selectedTab !== 0) {
      return;
    }
    if (!selectedIdea) {
      return;
    }
    setShowARModal(true);
  };
  const performAiAutoResearch = async (receivedParams) => {
    try {
      setShowARModal(false);
      setAIResearchStatus(true);
      let payload = {
        workspaceId: userWorkspace?.id,
        ideaId: selectedIdea?.id,
        marketId: selectedMarketOption?.market_id,
        subcategoryId: selectedMarketOption?.subcategory_id,
        isMarketSelected: receivedParams?.market,
        isSubCategorySelected: receivedParams?.subCategory,
        isCompetitorSelected: receivedParams?.competitorNames,
        isCompSubCatProfileSelected: receivedParams?.competitorProfiles,
      };
      dispatch(setActiveResearch(true));
      const socketConnection = io(socketUrl, {
        transports: ['websocket', 'polling'],
        withCredentials: true,
        reconnectionAttempts: 3,
      });
      socketConnection.on('connect', () => {
        socketConnection.emit('subscribeToResearch', activeUserId);
      });
      socketConnection.on('close', () => {
        // console.log('connection closed');
      });

      socketConnection.on('searchUpdate', (data) => {
        setAIResearchStatus((prevState) => {
          return {
            ...prevState,
            webpageSearched: data?.searchCount,
          };
        });
      });
      socketConnection.on('scrapeUpdate', (data) => {
        setAIResearchStatus((prevState) => {
          return {
            ...prevState,
            pageAnalysed: data?.pageCount,
          };
        });
        // console.log('Received scrapeUpdate event:', data);
      });
      setShowActiveResearchPanel(true);
      //Performing status updates after certain intervals.
      const interval = 5000;
      const keys = Object.keys(aiSearchStatus?.taskStatuses);
      keys.forEach((key, index) => {
        setTimeout(() => {
          setAIResearchStatus((prevState) => {
            return {
              ...prevState,
              taskStatuses: {
                ...prevState?.taskStatuses,
                [key]: true,
              },
            };
          });
        }, interval * index);
      });
      const response = await aiAutoResearchService(payload, dispatch);
      socketConnection.close();
      setAIResearchStatus((prevState) => {
        return {
          ...prevState,
          status: true,
        };
      });
      setAIResearchStatus((prevState) => {
        return {
          ...prevState,
          competitorEvaluated: Math.floor(Math.random() * 20) + 1,
          attributeConsidered: Math.floor(Math.random() * 20) + 1,
        };
      });
      setTimeout(() => {
        dispatch(setActiveResearch(false));
        setShowActiveResearchPanel(false);
      }, 5000);

      if (response) {
        fetchAndSetResearchDataForSelectedIdea();
      }
      setShowARModal(false);
    } catch (e) {
      setShowActiveResearchPanel(false);
      console.log(e);
    }
  };
  return (
    <Box mt={2} ml={2} mr={2} pb={3}>
      <Box className="displayFlex justifySpaceBetween alignItemsCenter">
        <Typography ml={2} component={'h1'} variant="dashboardCompHeading">
          {labels.research.title}
        </Typography>
        <Box className="textAlignRight displayFlex alignItemsStart">
          <Tooltip
            title={
              selectedTab === 0 && selectedIdea
                ? ''
                : 'Go to the ideas tab and select an idea to use AI Auto-Research'
            }
          >
            <IconButton
              onClick={showAIAutoResearchModal}
              className={
                selectedTab === 0 && selectedIdea
                  ? 'iconButtonType2 mr0'
                  : 'iconButtonType5 mr0'
              }
            >
              <EditIcon fontSize="small" className="mr05" />
              AI Auto-Research
            </IconButton>
          </Tooltip>
          <Typography className="ml05" variant="customStyle2">
            {labels.ideaDetails.pro}
          </Typography>
        </Box>
      </Box>
      <Box className="borderBottom2 borderColorGrey">
        <ResearchTabComp
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          tabData={tabOptions}
        />
      </Box>
      <Box>
        {selectedTab === 0 && (
          <IdeaTabContent
            AddMarket={AddMarket}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 1 && (
          <MarketTabContent
            // marketsList={marketsList?.sort((a, b) => a?.id - b?.id)}
            // workspaceDetails={userWorkspace}
            handleAddMarket={AddMarket}
            fetchMarketsListAndSetSelectedMarket={fetchMarketsListAndSetSelectedMarket}
            updateMarket={updateMarket}
          />
        )}
        {selectedTab === 2 && (
          <CompetitorTabContent
            handleAddCompetitor={AddCompetitor}
            competitorList={competitorList}
            assessmentList={assessmentList}
            fetchAssessmentsList={fetchAssessmentsList}
            fetchCompetitorsList={fetchCompetitorsList}
          />
        )}
      </Box>
      {showARModal && (
        <AutoResearchModal
          handleCancel={() => {
            setShowARModal(false);
          }}
          submitDetails={performAiAutoResearch}
        />
      )}
      {
        <ActiveResearchPanel
          researchStatus={aiSearchStatus}
          handleOpen={showActiveResearchPanel}
        />
      }
    </Box>
  );
};

export default ResearchComp;
