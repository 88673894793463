import { Box } from '@mui/material';
import TypingEffectTextField from '../IdeaDetails/TypingEffectTextField';
import { useState } from 'react';
import FinancialProjection from '../BusinessPlan/FinancialProjection';
const IdeaTabContent = ({
  tabQuestions = [],
  ideaId,
  submitAnswer = () => {},
  tabDataHandler = () => {},
  aiAutoFill = () => {},
  isDisabled,
  businessPlanScore,
}) => {
  const [questionLoader, setQuestionLoader] = useState({
    loading: false,
    forQuestion: null,
  });

  return (
    <Box>
      {tabQuestions?.map((questionOption, index) =>
        questionOption?.status && questionOption?.id !== 23 ? (
          <Box className="mt1" key={'bp_question' + questionOption?.id + index}>
            <TypingEffectTextField
              tabItem={questionOption}
              ideaId={ideaId}
              handleChange={tabDataHandler}
              handleBlur={submitAnswer}
              aiAutoFill={aiAutoFill}
              // isDisabled={
              //   questionOption?.id === 13 || isDisabled ? true : false
              // }
              questionLoader={questionLoader}
              setQuestionLoader={setQuestionLoader}
            ></TypingEffectTextField>
          </Box>
        ) : questionOption?.status && questionOption?.id === 23 ? (
          <FinancialProjection
            questionDetails={questionOption}
            handleBlur={submitAnswer}
            handleDataChange={tabDataHandler}
            aiAutoFill={aiAutoFill}
            isDisabled={isDisabled}
            businessPlanScore={businessPlanScore}
          />
        ) : (
          ''
        )
      )}
    </Box>
  );
};
export default IdeaTabContent;
