import DashboardLayout from '../Layouts/DashboardLayout';
import StudentsComp from './StudentsComp';

const StudentsContainer = () => {
  return (
    <DashboardLayout>
      <StudentsComp></StudentsComp>
    </DashboardLayout>
  );
};
export default StudentsContainer;