import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import {
  STATUS_200,
  STATUS_300,
  STATUS_403,
  STATUS_401,
} from '../data/constants';
import { toast } from 'react-toastify';
import { errorMessage } from '../lib/helper';
import { saved, saving, couldnotSave, resetStatus } from '../redux/saveSlice';;

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const getIdeaDetailsService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.ideaDetails}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const saveIdeaDetailsService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.saveIdeaDetails}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};

export const saveIdeaQuestionsService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.saveIdeaQuestionAnswers}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};

export const aiAutofillService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.ideaAiAutofill}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return resp?.data?.data;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      toast.error(errorMessage(resp), 2500);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    toast.error('Profile is not complete');
    return false;
  }
};

export const ideaShareUpdateService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.ideaShare}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};
export const getSharedIdeaDetailService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.sharedIdeaDetails}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
