// import Select from 'react-select';
// import { profileLabels } from '../../../data/constants';
// const CustomSelect = ({
//   handleBlur = () => { },
//   selectedValue,
//   dropDownItems,
//   handleChange,
//   multi,
//   disabled,
//   customStyles = {
//     marginTop: '15px',
//     minHeight: '40px',
//   },
// }) => {
//   return (
//     <Select
//       placeholder={profileLabels.selectOption}
//       styles={{
//         control: (baseStyles) => ({
//           ...baseStyles,
//           ...customStyles,
//         }),
//       }}
//       isMulti={multi}
//       value={selectedValue}
//       options={dropDownItems}
//       onChange={handleChange}
//       onBlur={handleBlur}
//       isDisabled={disabled}
//     />
//   );
// };

// export default CustomSelect;


import Select from 'react-select';
import { profileLabels } from '../../../data/constants';

// const CustomSelect = ({
//   handleBlur = () => { },
//   selectedValue,
//   dropDownItems,
//   handleChange,
//   multi,
//   disabled,
//   customStyles = {
//     marginTop: '15px',
//     minHeight: '40px',
//   },
// }) => {
//   console.log(selectedValue);
//   const isValueSelected = selectedValue && selectedValue.value !== undefined && selectedValue.label !== undefined;

//   return (
//     <Select
//       placeholder={profileLabels.selectOption}
//       styles={{
//         control: (baseStyles) => ({
//           ...baseStyles,
//           ...customStyles,
//         }),
//       }}
//       isMulti={multi}
//       value={isValueSelected ? selectedValue : null}
//       options={dropDownItems}
//       onChange={handleChange}
//       onBlur={handleBlur}
//       isDisabled={disabled}
//     />
//   );
// };

// export default CustomSelect;

const CustomSelect = ({
  handleBlur = () => { },
  selectedValue,
  dropDownItems,
  handleChange,
  multi,
  disabled,
  customStyles = {
    marginTop: '15px',
    minHeight: '40px',
  },
}) => {
  // Check if selectedValue has a valid value depending on whether it's an array or an object
  const isValueSelected = selectedValue && (
    Array.isArray(selectedValue)
      ? selectedValue.every(item => item.value !== undefined && item.label !== undefined)
      : selectedValue.value !== undefined && selectedValue.label !== undefined
  );

  return (
    <Select
      placeholder={profileLabels.selectOption}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          ...customStyles,
        }),
      }}
      isMulti={multi}
      value={isValueSelected ? selectedValue : null}
      options={dropDownItems}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={disabled}
    />
  );
};

export default CustomSelect;
