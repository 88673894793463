import ApiConfig from '../config/ApiConfig';
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from '../data/constants';
import axios from 'axios';
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';
import { saved, saving, couldnotSave, resetStatus } from '../redux/saveSlice';

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const getIdeaDetailsService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.ideaAndTabDetails}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const bnp_saveAnswerService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.bnp_questionAnswers}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};

export const fetchBusinessPlanService = async (payload) => {
  try {
    const response = await axios.post(
      `${ApiConfig.fetchBusinessPlan}`,
      payload,
      {
        responseType: 'blob',
      }
    );
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      return response?.data;
    } else {
      toast.error(errorMessage(response), 2500);
      throw response;
    }
  } catch (e) {
    toast.error("Could not download report with empty data");
  }
};


export const downloadSamplePlanReport = async (dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.get(ApiConfig.downloadSamplePlanReport, {
      responseType: 'blob',
    });
    return resp?.data ? resp?.data : false;
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    return false;
  }
};