import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabsData: null,
};

const studentsSlice = createSlice({
  name: 'studentsSlice',
  initialState,
  reducers: {
    setStudentsData: (state, action) => {
      state.tabsData = action.payload;
    },
  },
});

export const {
  setStudentsData,
} = studentsSlice.actions;

export default studentsSlice.reducer;
