import {
  Box,
  Typography,
} from '@mui/material';
import { labels } from '../../data/constants';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getUserDetailService } from '../../Services/AccountServices';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const PaymentSuccessComp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDetailService(dispatch);
  }, []);

  return (
    <>
      <Box mt={2} mr={2} pb={3}>
        <Typography
          component={'h2'}
          mt={5}
          fontSize={24}
          fontWeight={'bold'}
          textAlign={'center'}
        >
          {labels.thankyou}
        </Typography>
        <CheckCircleOutlineIcon className='enlargedIcon m8x34x4x49' fontSize='large' sx={{ color: 'green' }} />
        <Typography
          component={'h2'}
          mt={5}
          textAlign={'center'}
        >
          {labels.paymentSuccess}
        </Typography>
      </Box>
    </>
  );
};

export default PaymentSuccessComp;
