import {
  Box,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { labels } from '../../../data/constants';
import CustomSelect from '../../Custom Components/CustomSelect/CustomSelect';
import { useEffect, useState } from 'react';
import AddMarketOrCompetitorModal from '../AddMarketOrCompetitorModal';
import SubCategoryModal from '../Market/SubCategory/SubCategoryModal';
import { useSelector } from 'react-redux';
import {
  createMarketSubCategoryService,
  createResearchIdeaService,
  getResearchIdeaService,
  updateResearchIdeaService,
  downloadReport,
  downloadSampleReport
} from '../../../Services/ResearchServices';
import { useDispatch } from 'react-redux';
import {
  setSelectedIdea,
  setSelectedMarketOption,
  setSelectedMarketSubCatsForResearch,
} from '../../../redux/researchSlice';
import { saved, saving, couldnotSave, resetStatus } from '../../../redux/saveSlice';
import { logChanges } from '../../../Services/commentService';
import { fetchMarketsWithSubcategoriesService } from '../../../Services/ResearchServices';

const MarketSelectBlock = ({
  AddMarket = () => { },
  setSelectedTab = () => { },
}) => {
  const dispatch = useDispatch();
  const selectedIdea = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const selectedMarketOption = useSelector(
    (state) => state?.researchData?.selectedMarketOption
  );
  const marketsList = useSelector(
    (state) => state?.researchData?.marketsList
  );
  const marketWithCatDtls = useSelector(
    (state) => state?.researchData?.marketAndSubCats
  );
  const researchListForSelectedIdea = useSelector(
    (state) => state?.researchData?.researchListForSelectedIdea
  );
  const selectedMarketSubCatsForResearch = useSelector(
    (state) => state?.researchData?.selectedMarketSubCatsForResearch
  );
  const [recentSelectedMarket, setRecentSelectedMarket] = useState({
    label: selectedMarketOption?.market_name,
    value: selectedMarketOption?.id
  });
  const mappedMarketsList = marketsList?.map(market => ({
    label: market.market_name,
    value: market.id
  }));
  const [selectedMarketWithSubCats, setSelectedMarketWithSubCats] = useState(null);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [marketOptions, setMarketOptions] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedMarketSubCats, setSelectedMarketSubCats] = useState(null);
  const [showAddMarketModal, setShowAddMarketModal] = useState(null);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);

  useEffect(() => {
    setSelectedMarketWithSubCats(marketWithCatDtls?.find(market => market.market_id === selectedMarketOption?.id));
  }, [marketWithCatDtls, userWorkspace]);

  useEffect(() => {
    if (marketWithCatDtls?.length) {
      let marketOptions = marketWithCatDtls?.map((marketOption) => ({
        label: marketOption?.market_name,
        value: marketOption?.market_id,
      }));
      setMarketOptions(marketOptions);
    }
  }, [marketWithCatDtls]);
  useEffect(() => {
    // setSelectedMarket(null);
    if (!selectedIdea) {
      return;
    }
    if (selectedMarketOption) {
      let matchedMarket = marketOptions.filter(
        (marketOpt) => marketOpt?.value === selectedMarketOption?.id
      );
      setSelectedMarket(matchedMarket[0]);
      setRecentSelectedMarket({
        label: selectedMarketOption?.market_name,
        value: selectedMarketOption?.id,
      });
    } else {
      if (researchListForSelectedIdea?.length && mappedMarketsList?.length) {
        let matchedMarket = mappedMarketsList.filter(
          (marketOpt) =>
            marketOpt?.value === researchListForSelectedIdea[0]?.market_id
        );
        setSelectedMarket(matchedMarket[0]);
        const temp = marketsList.find(market => market.id === researchListForSelectedIdea[0]?.market_id);
        dispatch(setSelectedMarketOption(temp));
      } else {
        setRecentSelectedMarket(null);
      }
    }
  }, [selectedIdea, mappedMarketsList, selectedMarketOption]);

  useEffect(() => {
    if (selectedMarket) {
      setSelectedMarketSubCats(null);
      let subCats = marketWithCatDtls?.filter(
        (marketOpt) => marketOpt?.market_id === selectedMarket?.value
      );
      setSelectedMarketSubCats(subCats);
    } else {
      setSelectedMarketSubCats(null);
    }
  }, [selectedMarket, marketWithCatDtls]);

  const handleMarketSelectChange = async (selectedMarket) => {
    if (researchListForSelectedIdea?.length) {
      researchListForSelectedIdea?.forEach(async (market, index) => {
        if (market?.market_id === selectedMarket?.value) {
          setRecentSelectedMarket(selectedMarket);
          const temp = marketsList.find(market => market.id === selectedMarket?.value);
          dispatch(setSelectedMarketOption(temp));
          return;
        } else {
          try {
            let payload = {
              workspaceId: userWorkspace?.id,
              ideaId: selectedIdea?.id,
              marketId: selectedMarket?.value,
            };
            const response = await createResearchIdeaService(payload, dispatch);
            if (response) {
              let payload = {
                workspaceId: userWorkspace?.id,
                ideaId: selectedIdea?.id,
              };
              const response = await getResearchIdeaService(payload, dispatch);
              if (response) {
                const temp = marketsList.find(market => market.id === selectedMarket?.value);
                dispatch(setSelectedMarketOption(temp));
                let changeLogPayload = {
                  changeDetail: `Idea '${selectedIdea.name}' updated`,
                  workspaceId: userWorkspace.id,
                };
                await logChanges(changeLogPayload, dispatch);
              } else {
                setRecentSelectedMarket(null);
                dispatch(setSelectedMarketOption(null));
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
    else {
      setRecentSelectedMarket(null);
      dispatch(setSelectedMarketOption(null));
    }
  }

  const handleSwitchChange = async (e, subCat) => {
    try {
      let value = e.target.checked;
      let updatedSubCategoryIds;
      if (value) {
        updatedSubCategoryIds = [...subCategoryIds, subCat.sub_category_id];
      } else {
        updatedSubCategoryIds = subCategoryIds.filter(
          (id) => id !== subCat.sub_category_id
        );
      }
      setSubCategoryIds(updatedSubCategoryIds);
      console.log(updatedSubCategoryIds);
      dispatch(setSelectedMarketSubCatsForResearch(updatedSubCategoryIds));
      let payload = {
        workspaceId: userWorkspace?.id,
        subcategoryId: subCat?.sub_category_id,
        subcategoryFlag: value.toString(),
        researchIdeaDetailsId: researchListForSelectedIdea[0]?.id,
      };
      dispatch(saving());
      const response = await updateResearchIdeaService(payload, dispatch);
      if (response) {
        let payload = {
          workspaceId: userWorkspace?.id,
          ideaId: selectedIdea?.id,
        };
        await getResearchIdeaService(payload, dispatch);
        dispatch(saved());
        let selectedMarketIndex;
        researchListForSelectedIdea?.forEach((opt, index) => {
          if (opt?.market_id === selectedIdea?.id) {
            selectedMarketIndex = index;
          }
        });
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const addNewSubCat = async (subCategoryName, isNewSubCategory) => {
    try {
      let payload = {
        marketId: selectedMarketOption?.id,
        subCategoryName: subCategoryName,
        newSubCategory: isNewSubCategory,
      };
      const response = await createMarketSubCategoryService(
        payload,
        dispatch,
        true
      );
      if (response) {
        let tempPayload = {
          workspaceId: userWorkspace?.id,
        };
        // setSubCategoryIds([...subCategoryIds, response.id]);
        await fetchMarketsWithSubcategoriesService(tempPayload, dispatch);
        let payload = {
          workspaceId: userWorkspace?.id,
          subcategoryId: response?.id,
          subcategoryFlag: 'true',
          researchIdeaDetailsId: researchListForSelectedIdea[0]?.id,
        };
        const addResponse = await updateResearchIdeaService(payload, dispatch);
        if (addResponse) {
          let payload = {
            workspaceId: userWorkspace?.id,
            ideaId: selectedIdea?.id,
          };
          await getResearchIdeaService(payload, dispatch);
          let ideaDetail = JSON.parse(JSON.stringify(selectedIdea));
          let selectedMarketIndex;
          ideaDetail?.marketList?.forEach((opt, index) => {
            if (opt?.market_id === selectedMarketWithSubCats?.market_id) {
              selectedMarketIndex = index;
            }
          });
          setShowAddSubCatModal(false);
        }
        let changeLogPayload = {
          changeDetail: `Idea '${selectedIdea.name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const handleAddMarket = async (marketName) => {
    const response = await AddMarket(marketName);
    if (response) {
      setShowAddMarketModal(false);
    }
  };

  const handleDownload = async () => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        marketId: selectedMarket?.value,
        subcategoryId: subCategoryIds,
        ideaId: selectedIdea?.id,

      };
      dispatch(saving());
      const response = await downloadReport(payload, dispatch);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'marketreport.pdf');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        dispatch(saved());
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  }
  const handleSampleDownload = async () => {
    try {
      dispatch(saving());
      const response = await downloadSampleReport(dispatch);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'samplemarketreport.pdf');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        dispatch(saved());
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  }

  return (
    <Box className="border3 borderColorBlue mt1 borderRadius8 padding1">
      <Grid container columnSpacing={4}>
        <Grid item lg={6}>
          <Box className="displayFlex alignItemsCenter">
            <Typography
              className="mt1 mb1 mr05"
              variant="dashboardCompHeading5"
            >
              {labels.research.ideaTab.marketSelectBlock.heading}
            </Typography>
            <Button
              className="ml0"
              variant="textButton1"
              disabled={selectedIdea ? false : true}
              onClick={() => {
                if (selectedMarket) {
                  setSelectedTab(1);
                }
              }}
            >
              {labels.research.ideaTab.marketSelectBlock.dtlBtnLabel}
            </Button>
          </Box>
          <CustomSelect
            selectedValue={recentSelectedMarket}
            handleChange={(value) => {
              handleMarketSelectChange(value);
            }}
            dropDownItems={mappedMarketsList}
            multi={false}
            customStyles={{
              marginTop: '0.5rem',
              minHeight: '40px',
            }}
            disabled={selectedIdea ? false : true}
          />
          <Box className="displayFlex justifySpaceBetween mt1">
            <Button
              disabled={selectedIdea ? false : true}
              onClick={() => {
                setShowAddMarketModal(true);
              }}
              className="textTrasformCapital"
              variant="buttonVariant1"
            >
              {labels.research.ideaTab.marketSelectBlock.addMarketBtnLabel}
            </Button>
            <Box className="displayFlex flexDirectionColumn">
              <Button
                className="textTrasformCapital"
                disabled={selectedMarket?.value ? false : true}
                variant="buttonVariant1"
                onClick={handleDownload}
              >
                {
                  labels.research.ideaTab.marketSelectBlock
                    .downloadReportBtnLabel
                }
              </Button>
              <Button
                className="textTrasformCapital mt05"
                variant="textButton2"
                onClick={handleSampleDownload}
              >

                {labels.research.ideaTab.marketSelectBlock.sampleReportBtnLabel}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          {
            <Box component={'div'}>
              <Typography
                component={'p'}
                className="mt1 mb1 mr1"
                variant="dashboardCompHeading5"
              >
                {labels.research.ideaTab.marketSelectBlock.subHeading}
              </Typography>
              {selectedIdea &&
                selectedMarketWithSubCats?.sub_categories?.map(
                  (subCatOption, index) => (
                    <FormControlLabel
                      className="displayBlock"
                      key={'subCatOption' + index}
                      label={subCatOption?.sub_category_name}
                      control={
                        <Switch
                          onChange={(e) => { handleSwitchChange(e, subCatOption); }}
                          checked={selectedMarketSubCatsForResearch?.includes(subCatOption?.sub_category_id) ? true : false}
                        />
                      }
                    />
                  )
                )}
              <Button
                className="textTrasformCapital mt1"
                variant="buttonVariant1"
                disabled={!selectedMarketSubCats ? true : false}
                onClick={() => {
                  setShowAddSubCatModal(true);
                }}
              >
                {labels.research.ideaTab.marketSelectBlock.addSubCatBtnLabel}
              </Button>
            </Box>
          }
        </Grid>
      </Grid>
      {showAddMarketModal && (
        <AddMarketOrCompetitorModal
          toAdd={'Market'}
          dialogTitle={labels.research.addMarketModal.title}
          handleClose={() => {
            setShowAddMarketModal(false);
          }}
          submitMarketDetail={handleAddMarket}
        />
      )}
      {showAddSubCatModal && (
        <SubCategoryModal
          handleCancel={() => {
            setShowAddSubCatModal(false);
          }}
          submitDetails={addNewSubCat}
        />
      )}
    </Box>
  );
};
export default MarketSelectBlock;
