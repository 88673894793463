import ApiConfig from "../config/ApiConfig";
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from "../data/constants";
import axios from "axios";
import { errorMessage } from "../lib/helper";
import { toast } from "react-toastify";
import { couldnotSave, saved, saving, resetStatus } from '../redux/saveSlice';
import { setAllWorkspaces, setSelectedWorkspace } from "../redux/login";
import { setJobCenterData } from "../redux/jobCenterSlice";

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const listWorkspace = async (dispatch) => {
  try {
    const resp = await axios.get(`${ApiConfig.listWorkspace}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(setAllWorkspaces(resp?.data?.data));
      return resp?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
  }
};
export const editWorkspaceName = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.editWorkspaceName}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(setSelectedWorkspace(resp?.data?.data));
      dispatch(setJobCenterData(null));
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    return false;
  }
};
export const deleteWorkspace = async (payload) => {
  try {
    const resp = await axios.delete(`${ApiConfig.deleteWorkspace}`, {
      data: payload,
    });
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const createWorkspace = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.createWorkspace}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(setSelectedWorkspace(resp?.data?.data));
      dispatch(setJobCenterData(null));
      dispatch(saved());
      return resp?.data?.data;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      toast.error(errorMessage(resp), 2500);
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), 2500);
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
  }
};
export const sendInvite = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.invite}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
  }
};

export const getGuestWorkspacePrivelegesService = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.guestWorkspacePriveleges}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
  }
};
export const getCollaboratorsList = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.listCollaborators}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
  }
};
export const editCollaborator = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.editCollaborator}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};
export const deleteCollaborator = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.delete(`${ApiConfig.deleteCollaborator}`, {
      data: payload,
    });
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};
export const reInviteCollaborator = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.reinviteCollaborator}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};

export const acceptCollaboratorWorkspace = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.acceptCollaborator}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return resp?.data;
    } else {
      dispatch(couldnotSave());
      throw new Error(resp?.data?.message);
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    throw new Error(e.response?.data?.message);
  }
};

export const getUserDetailsFromWorkspace = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.userWorkspaceDetail}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data;
    } else {
      throw new Error(resp?.data?.message);
    }
  } catch (e) {
    throw new Error(e.response?.data?.message);
  }
};