import { Box, Grid, Button, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import CustomSelect from '../Custom Components/CustomSelect/CustomSelect';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setResearchListForSelectedIdea,
  setSelectedIdea,
  setSelectedMarketOption,
  setSelectedMarketSubCatsForResearch
} from '../../redux/researchSlice';
import { useEffect, useState } from 'react';
import { getMarketListService, getResearchIdeaService } from '../../Services/ResearchServices';
const IdeaSelectorComp = ({
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedIdeaDetails = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const ideasList = useSelector(
    (state) => state?.researchData?.ideasList
  );
  const [recentSelectedIdea, setRecentSelectedIdea] = useState({
    label: selectedIdeaDetails?.name,
    value: selectedIdeaDetails?.id
  });
  const mappedIdeasList = ideasList?.map(idea => ({
    label: idea.name,
    value: idea.id,
  }));

  useEffect(() => {
    if (!selectedIdeaDetails?.id) {
      setRecentSelectedIdea(null);
    }
  }, [selectedIdeaDetails]);
  // useEffect(() => {
  //   handleIdeaSelectChange({});
  // }, [ideasList]);

  const handleIdeaSelectChange = async (value) => {
    try {
      dispatch(setSelectedMarketSubCatsForResearch(null));
      //Filtering out selected idea details from idea list.
      let idea = ideasList.filter((ideaOpt) => ideaOpt?.id === value?.value);
      dispatch(setSelectedIdea(idea[0]));
      dispatch(setResearchListForSelectedIdea(null));
      dispatch(setSelectedMarketOption(null));
      setRecentSelectedIdea(value);
      // if (forComp === 'research') {
      //   let payload = {
      //     workspaceId: userWorkspace?.id,
      //     ideaId: idea[0]?.id,
      //   };
      //   const response = await getResearchIdeaService(payload, dispatch);
      //   //If idea details are present in research Idea details then set them in redux.
      //   if (response?.researchIdeaDetails?.length) {
      //     const marketFetchPayload = {
      //       workspaceId: userWorkspace?.id,
      //     }
      //     await getMarketListService(marketFetchPayload, dispatch);
      //     marketsList.map(market => {
      //       if (market.id === response?.researchIdeaDetails[0]?.market_id) {
      //         dispatch(setSelectedMarketOption(market));
      //       }
      //     });
      //   } else {
      //     dispatch(setSelectedMarketOption(''));
      //   }
      // } else if (forComp === 'businessPlan') {

      // }
    } catch (e) {
      console.log(e)
    }
    // if (forComp === 'research') {
    //   try {
    //     //Filtering out selected idea details from idea list.
    //     let idea = ideasList.filter((ideaOpt) => ideaOpt?.id === value?.value);
    //     dispatch(setSelectedIdea(idea[0]));
    //     setRecentSelectedIdea(value);
    //     let payload = {
    //       workspaceId: userWorkspace?.id,
    //       ideaId: idea[0]?.id,
    //     };
    //     const response = await getResearchIdeaService(payload, dispatch);
    //     //If idea details are present in research Idea details then set them in redux.
    //     if (response?.researchIdeaDetails?.length) {
    //       const marketFetchPayload = {
    //         workspaceId: userWorkspace?.id,
    //       }
    //       await getMarketListService(marketFetchPayload, dispatch);
    //       marketsList.map(market => {
    //         if (market.id === response?.researchIdeaDetails[0]?.market_id) {
    //           dispatch(setSelectedMarketOption(market));
    //         }
    //       });
    //     } else {
    //       dispatch(setSelectedMarketOption(''));
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // } else if (forComp === 'businessPlan') {
    //   let idea = ideasList.filter((ideaOpt) => ideaOpt?.id === value?.value);
    //   console.log(idea[0]);
    //   dispatch(setSelectedIdea(idea[0]));
    //   // handleIdeaChange(value?.value);
    // }
  };

  return (
    <Grid mt={1} p={2} container className="backgroundLightGrey borderRadius8">
      <Grid item xs={12} lg={6}>
        <Box className="displayFlex alignItemsCenter">
          <Typography className="mr1 mb0" variant="dashboardCompHeading5">
            {labels.businessPlan.ideaSelector.title}
          </Typography>
          <Box className="flex1">
            <CustomSelect
              selectedValue={recentSelectedIdea}
              handleChange={(value) => {
                handleIdeaSelectChange(value);
              }}
              dropDownItems={mappedIdeasList}
              multi={false}
              customStyles={{
                marginTop: '0px',
                minHeight: '40px',
                marginRight: '16px',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6} className="displayFlex alignItemsCenter">
        {selectedIdeaDetails ? (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="paraText1">
              {selectedIdeaDetails?.description}
              <Button
                variant="textButton2"
                onClick={() => {
                  navigate(`/businessIdeas/${selectedIdeaDetails?.id}`);
                }}
              >
                {labels.businessIdeas.businessIdeasContainer.detailButton}
              </Button>
            </Typography>
          </Box>
        ) : (
          <Typography variant="dashboardCompHeading5">
            {labels.businessPlan.ideaSelector.subTitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default IdeaSelectorComp;
