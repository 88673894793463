import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Tooltip,
} from '@mui/material';
import { labels } from '../../data/constants';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadSamplePlanReport, fetchBusinessPlanService } from '../../Services/businessPlanServices';
import { useDispatch } from 'react-redux';
import { saving, saved, couldnotSave, resetStatus } from '../../redux/saveSlice';

const BusinessPlanHeader = ({ selectedIdea = null, userWorkspace = null }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const downloadBusinessPlan = async () => {
    try {
      if (!selectedIdea || !userWorkspace) {
        return;
      }
      setLoader(true);
      const payload = {
        workspaceId: userWorkspace,
        ideaId: selectedIdea,
      };
      const response = await fetchBusinessPlanService(payload);
      if (response) {
        setLoader(false);
        const pdfBlob = response;
        const url = window.URL.createObjectURL(new Blob([pdfBlob]));
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'businessPlan.docx');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      } else {
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };
  const handleSamplePlanDownload = async () => {
    try {
      dispatch(saving());
      const response = await downloadSamplePlanReport(dispatch);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));        // const url = window.URL.createObjectURL(new Blob([pdfBlob]));
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'samplemarketreport.pdf');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        dispatch(saved());
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  }
  return (
    <Box className="displayFlex justifySpaceBetween alignItemsCenter">
      <Box>
        <Typography component={'h1'} variant="dashboardCompHeading">
          {labels.businessPlan.title}
        </Typography>
        <Typography mt={1} variant="paraText1">
          {labels.businessPlan.subTitle}
        </Typography>
      </Box>
      <Box className="textAlignRight">
        <Tooltip
          title={
            !selectedIdea || !userWorkspace
              ? 'Please select an Idea.'
              : 'Download Business Plan'
          }
        >
          <IconButton
            disabled={loader === false ? false : true}
            onClick={downloadBusinessPlan}
            className="iconButtonType2"
          >
            <DownloadIcon fontSize="small" className="mr05" />
            {labels.businessPlan.exportBtnLabel}
            {loader && <CircularProgress className="ml05" size={'1rem'} />}
          </IconButton>
        </Tooltip>
        <Button className="displayBlock mt05" variant="textButton2" onClick={handleSamplePlanDownload}>
          {labels.businessPlan.samplePlanLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default BusinessPlanHeader;
