import React, { useState } from 'react';
import CustomDialog from '../Custom Components/CustomDialog/CustomDialog';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { saved, saving, couldnotSave, resetStatus } from '../../redux/saveSlice';
import { useDispatch } from 'react-redux';
import { downloadSampleReport } from '../../Services/ResearchServices';

import {
  Box,
  Button,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { labels } from '../../data/constants';

const AutoResearchModal = ({
  handleCancel = () => { },
  submitDetails = () => { },
}) => {
  const dispatch = useDispatch();
  const [market, setMarket] = useState(true);
  const [subCategory, setSubCategory] = useState(true);
  const [competitorNames, setCompetitorNames] = useState(true);
  const [competitorProfiles, setCompetitorProfiles] = useState(true);

  const handleSubmit = () => {
    submitDetails({
      market,
      subCategory,
      competitorNames,
      competitorProfiles,
    });
  };

  const handleToggle = (switchType) => {
    switch (switchType) {
      case 'market':
        setMarket((prevState) => !prevState);
        break;
      case 'subCategory':
        setSubCategory((prevState) => !prevState);
        break;
      case 'competitorNames':
        setCompetitorNames((prevState) => !prevState);
        break;
      case 'competitorProfiles':
        setCompetitorProfiles((prevState) => !prevState);
        break;
      default:
        break;
    }
  };
  const handleSampleDownload = async () => {
    try {
      dispatch(saving());
      const response = await downloadSampleReport(dispatch);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));        // const url = window.URL.createObjectURL(new Blob([pdfBlob]));
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'samplemarketreport.pdf');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        dispatch(saved());
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  }

  return (
    <CustomDialog
      handleOpen={true}
      showClose={false}
      width={'xs'}
      dialogTitle="AI Auto-Research"
      headingVariant="modalHeading1"
    >
      <Box className="displayFlex alignItemsStart tipBackgroundWarning padding12x16 borderRadius4 tipColorWarning">
        <TipsAndUpdatesIcon fontSize="small" />
        <Box className="ml1">
          <Typography className="fontSize14 fontWeight700 mb1">Tip</Typography>
          <Typography className="fontSize14 colorBlack">
            For the most powerful results, follow these steps:
            <ol>
              <li>Auto-Research only the Market and Sub-category</li>
              <li>Manually edit the Market or Sub-category names if needed</li>
              <li>
                If you already know competitors who should be included in the
                analysis, manually add their names
              </li>
              <li>Auto-Research the competitor elements</li>
            </ol>
            Have fun!
          </Typography>
        </Box>
      </Box>
      <Typography className="fontSize14 colorBlack mt1">
        Which elements do you want to use Auto-Research on right now?
      </Typography>
      <Box className="mt1">
        <FormControlLabel
          label="Market (1 credit)"
          control={<Switch checked={market} onChange={() => handleToggle('market')} />}
        />
        <FormControlLabel
          label="Sub-Category (1 credit)"
          control={<Switch checked={subCategory} onChange={() => handleToggle('subCategory')} />}
        />
        <FormControlLabel
          label="Competitor Names (5 credits)"
          control={<Switch checked={competitorNames} onChange={() => handleToggle('competitorNames')} />}
        />
        <FormControlLabel
          label="Comp. Sub-Cat Profiles (20 credits)"
          control={<Switch checked={competitorProfiles} onChange={() => handleToggle('competitorProfiles')} />}
        />
      </Box>
      <Button className="textTrasformCapital mt05" variant="textButton2" onClick={handleSampleDownload}>
        Download example report

      </Button>

      <Box className="justifySpaceBetween displayFlex mt1_5">
        <Button variant="buttonVariant1" onClick={handleCancel}>
          {labels.commonLabels.cancelLabel}
        </Button>
        <Button variant="buttonVariant1" onClick={handleSubmit}>
          {labels.commonLabels.submitLabel}
        </Button>
      </Box>
    </CustomDialog>

  );
};

export default AutoResearchModal;
