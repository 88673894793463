import axios from 'axios';
import ApiConfig from "../config/ApiConfig";
import { STATUS_200, STATUS_300, STATUS_401, STATUS_403 } from "../data/constants";
import { saved, saving, couldnotSave, resetStatus } from '../redux/saveSlice';
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const getAllPlans = async () => {
  try {
    const resp = await axios.get(`${ApiConfig.listProPlans}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const makePayment = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.makePayment}`, payload);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return resp?.data;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    return false;
  }
};

export const manageSubscription = async (dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.managesubscription}`);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return resp?.data;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    return false;
  }
};
