import ApiConfig from '../config/ApiConfig';
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from '../data/constants';
import axios from 'axios';
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';
import { saved, saving, couldnotSave, resetStatus } from '../redux/saveSlice';
import { setIdeasList, setSelectedIdea } from '../redux/researchSlice';
const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const getBusinessIdeasService = async (payload, dispatch) => {
  try {
    const resp = await axios.post(`${ApiConfig.listBusinessIdeas}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(setIdeasList(resp?.data?.data));
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const addManualBusinessIdeaService = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.generateManualBusinessIdeas}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message);
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const generateBusinessIdeasService = async (payload, dispatch) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.generateBusinessIdeas}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message);
      dispatch(setSelectedIdea(null));
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const ideaLikeDislikeService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.likeDislike}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};
export const ideaLockService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.ideaLock}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};
export const ideaVariantService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.ideaVariant}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};
export const getFilteredIdeas = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.categorizedIdeas}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (error) {
    showErrorMessage(error);
    return false;
  }
};
