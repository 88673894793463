import {
  Box,
  IconButton,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Grid,
  Stack,
  Chip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import StrengthAndWeaknessPanel from '../StrengthAndWeaknessPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  addAssessmentService,
  createStrengthWeaknessService,
  deleteCompSubCatService,
  deleteStrengthWeaknessService,
  saveCompSubCatQuestionDtlService,
  updateMarketSubCategoryService,
  updateStrengthAndWeaknessService,
} from '../../../Services/ResearchServices';
import AddAssessmentModal from '../AddAssessmentModal';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ResearchDeleteModal from '../ResearchDeleteModal';
import { labels } from '../../../data/constants';
import { useSelector, useDispatch } from 'react-redux';
import { saved, saving, couldnotSave, resetStatus } from '../../../redux/saveSlice';
import { logChanges } from '../../../Services/commentService';

const CompetitorMarketSubCategoryProfile = ({
  subCategoryProfile,
  fetchCompetitorDetail = () => { },
  competitorId = '',
  competitorName = '',
  workspaceId = '',
  assessmentList = [],
  fetchAssessmentsList = () => { },
}) => {
  const dispatch = useDispatch();
  const [subCatProfileData, setSubCatProfileData] = useState(null);
  const [subCatProfileDataCopy, setSubCatProfileDataCopy] = useState(null);
  const [nameEdit, setNameEdit] = useState(false);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [showSubCatDltModal, setShowSubCatDltModal] = useState(false);
  const [nameError, setNameError] = useState({
    status: false,
    msg: '',
  });
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  useEffect(() => {
    if (subCategoryProfile) {
      setSubCatProfileData(JSON.parse(JSON.stringify(subCategoryProfile)));
      setSubCatProfileDataCopy(JSON.parse(JSON.stringify(subCategoryProfile)));
    }
  }, [subCategoryProfile]);
  const updateSubCatProfileName = async () => {
    try {
      if (
        subCatProfileData?.sub_category_name ===
        subCatProfileDataCopy?.sub_category_name
      ) {
        setNameError({
          status: true,
          msg: 'Name not changed!',
        });
        return;
      }
      if (!subCatProfileData?.sub_category_name) {
        setNameError({
          status: true,
          msg: 'Name cannot be blank!',
        });
        return;
      }
      let payload = {
        subCategoryId: subCatProfileData?.id,
        marketId: subCatProfileData?.market_id,
        subCategoryName: subCatProfileData?.sub_category_name,
      };
      dispatch(saving());
      const response = updateMarketSubCategoryService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setSubCatProfileDataCopy((prevState) => {
          return {
            ...prevState,
            sub_category_name: subCatProfileData?.sub_category_name,
          };
        });
        setNameEdit(false);
        let changeLogPayload = {
          changeDetail: `Market Sub-Category '${subCatProfileData.sub_category_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };

  const addStrengthAndWeakness = async (fieldValue, typeValue) => {
    try {
      let payload = {
        subCategoryId: subCatProfileData?.id,
        name: fieldValue,
        type: typeValue,
      };
      dispatch(saving());
      const response = await createStrengthWeaknessService(payload, dispatch);
      if (response) {
        dispatch(saving());
        fetchCompetitorDetail();
        let changeLogPayload = {
          changeDetail: `Custom Strength/Weakness '${fieldValue}' created in '${subCatProfileData.sub_category_name}' sub-category`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const deleteStrengthAndWeakness = async (strengthId) => {
    try {
      let payload = {
        strengthWeaknessId: strengthId,
      };
      dispatch(saving());
      const response = await deleteStrengthWeaknessService(payload, dispatch);
      if (response) {
        dispatch(saved());
        fetchCompetitorDetail();
        let changeLogPayload = {
          changeDetail: `Custom Strength/Weakness '${response.name}' deleted from '${subCatProfileData.sub_category_name}' sub-category`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const updateStrengthAndWeakness = async (level, swId) => {
    try {
      let payload = {
        strengthWeaknessId: swId,
        level: level,
        workspaceId: workspaceId,
        competitorId: competitorId,
        subcategoryId: subCatProfileData?.id,
      };
      dispatch(saved());
      const response = await updateStrengthAndWeaknessService(
        payload,
        dispatch
      );
      if (response) {
        dispatch(saving());
        fetchCompetitorDetail();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorName}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const handleAnswerChange = (answerReceived, questionId) => {
    let subCatData = subCatProfileData;
    subCatData?.questionAnswers?.forEach((questionOption) => {
      if (questionOption?.id === questionId) {
        questionOption.answer = answerReceived;
      }
    });
    setSubCatProfileData(() => {
      return {
        ...subCatData,
      };
    });
  };
  const handleAnswerSubmit = async (answerReceived, questionId) => {
    try {
      let isAnswerChanged = '';
      subCatProfileDataCopy?.questionAnswers?.forEach((questionOption) => {
        if (questionOption?.id === questionId) {
          if (questionOption?.answer === answerReceived) {
            isAnswerChanged = false;
          } else {
            isAnswerChanged = true;
          }
        }
      });

      if (!isAnswerChanged) {
        return;
      }

      let payload = {
        questionId,
        subcategoryId: subCatProfileData?.id,
        competitorId: competitorId,
        marketId: subCatProfileData?.market_id,
        workspaceId,
        answer: answerReceived || ' ',
      };
      const response = await saveCompSubCatQuestionDtlService(payload, dispatch);
      if (response) {
        let dataCopy = subCatProfileDataCopy;
        dataCopy?.questionAnswers?.forEach((questionOption) => {
          if (questionOption?.id === questionId) {
            questionOption.answer = answerReceived;
          }
        });
        setSubCatProfileDataCopy(() => {
          return {
            ...dataCopy,
          };
        });
        let changeLogPayload = {
          changeDetail: `Competitor sub-cat profile '${subCatProfileData?.sub_category_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const addAssessment = async (recievedValue) => {
    try {
      let payload = {
        name: recievedValue,
      };
      dispatch(saving());
      const response = await addAssessmentService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setShowAssessmentModal(false);
        fetchAssessmentsList();
        handleAnswerSubmit(recievedValue, 5);
        handleAnswerChange(recievedValue, 5);
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorName}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const deleteSubCatProfile = async (receivedValue) => {
    try {
      let payload = {
        workspaceId: workspaceId,
        competitorId: competitorId,
        subcategoryId: receivedValue,
      };
      dispatch(saving());
      const response = await deleteCompSubCatService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setShowSubCatDltModal(false);
        fetchCompetitorDetail();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorName}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      setShowSubCatDltModal(false);
      console.log(e);
    }
  };
  return (
    <Box className="tipBackgroundWarning borderRadius8 padding12x16 mt1">
      <Box className="displayFlex alignItemsCenter">
        <Typography
          component={'h4'}
          variant="dashboardCompHeading"
          className="fontSize18 textTrasformCapital mr1"
        >
          {subCatProfileData?.sub_category_name}
        </Typography>
        <IconButton
          onClick={() => {
            setShowSubCatDltModal(true);
          }}
          aria-label="delete"
          color="error"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
      <InputLabel
        sx={{
          overflow: 'visible',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        shrink={false}
        className="dashboardLabel1"
      >
        <Typography>{labels.research.strengthsWeaknessesHeading}</Typography>
      </InputLabel>
      <StrengthAndWeaknessPanel
        SWOptionsArray={subCategoryProfile?.strengthWeaknesses}
        handleAddStrength={addStrengthAndWeakness}
        handleStrengthDelete={deleteStrengthAndWeakness}
        handleStrengthUpdate={updateStrengthAndWeakness}
      />
      <Accordion className="mt1 customAccordion1">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Details
        </AccordionSummary>
        <AccordionDetails>
          {subCatProfileData?.questionAnswers?.map((questionOption, index) =>
            questionOption?.id !== 5 && questionOption?.id !== 6 ? (
              <Box key={'qustionOption' + index}>
                <InputLabel
                  shrink={false}
                  className="dashboardLabel1 overflowVisible"
                >
                  <Typography variant="customStyle1">
                    {questionOption?.question}
                  </Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  className="textFieldType4 backgroundWhite"
                  multiline
                  value={questionOption?.answer || ''}
                  onChange={(e) =>
                    handleAnswerChange(e.target.value, questionOption?.id)
                  }
                  onBlur={(e) =>
                    handleAnswerSubmit(e.target.value, questionOption?.id)
                  }
                />
              </Box>
            ) : (
              ''
            )
          )}
        </AccordionDetails>
      </Accordion>
      <Grid className="mt1" container columnSpacing={4}>
        {subCatProfileData?.questionAnswers?.map((questionOption) =>
          questionOption?.id === 5 || questionOption?.id === 6 ? (
            <Grid key={'questionOption' + questionOption?.id} item lg={6}>
              <InputLabel
                sx={{ overflow: 'visible' }}
                shrink={false}
                className="dashboardLabel1"
              >
                <Typography variant="customStyle1">
                  {questionOption?.question}
                </Typography>
              </InputLabel>
              {questionOption?.id === 5 ? (
                <Stack direction={'row'} className="mt1 flexWrap" spacing={1}>
                  {assessmentList?.map((assessmentOption) => (
                    <Chip
                      label={assessmentOption?.competitive_assessment_name}
                      className="backgroundWhite mb05"
                      clickable
                      variant="outlined"
                      icon={
                        questionOption?.answer ===
                          assessmentOption?.competitive_assessment_name ? (
                          <DoneOutlinedIcon fontSize="small" />
                        ) : (
                          ''
                        )
                      }
                      color={
                        questionOption?.answer ===
                          assessmentOption?.competitive_assessment_name
                          ? 'success'
                          : 'default'
                      }
                      onClick={() => {
                        handleAnswerChange(
                          assessmentOption?.competitive_assessment_name,
                          questionOption?.id
                        );
                        handleAnswerSubmit(
                          assessmentOption?.competitive_assessment_name,
                          questionOption?.id
                        );
                      }}
                    />
                  ))}
                  <Chip
                    label="Other+"
                    className="backgroundWhite mb05"
                    variant="outlined"
                    clickable
                    onClick={() => {
                      setShowAssessmentModal(true);
                    }}
                  />
                </Stack>
              ) : (
                <TextField
                  fullWidth
                  className="textFieldType4 backgroundWhite"
                  multiline
                  value={questionOption?.answer || ''}
                  onChange={(e) =>
                    handleAnswerChange(e.target.value, questionOption?.id)
                  }
                  onBlur={(e) =>
                    handleAnswerSubmit(e.target.value, questionOption?.id)
                  }
                />
              )}
            </Grid>
          ) : (
            ''
          )
        )}
      </Grid>
      {showAssessmentModal && (
        <AddAssessmentModal
          handleCancel={() => setShowAssessmentModal(false)}
          submitDetails={(receivedValue) => {
            addAssessment(receivedValue);
          }}
        />
      )}
      {showSubCatDltModal && (
        <ResearchDeleteModal
          title="Delete Competitor Sub-Category Profile"
          message1="Are you sure you want to delete this competitor Sub-Category Profile data?"
          confirmDeleteMarket={() => {
            deleteSubCatProfile(subCatProfileData?.id);
          }}
          handleClose={() => {
            setShowSubCatDltModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default CompetitorMarketSubCategoryProfile;
