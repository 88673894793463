import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { toast } from 'react-toastify';
import { errorMessage } from '../lib/helper';
import { saving, saved, couldnotSave, resetStatus } from '../redux/saveSlice';

export const showToastSuccess = (msg) => {
  return toast.success(msg);
};

export const getUserTabsService = async (payload) => {
  try {
    const response = await axios.post(`${ApiConfig.userTabs}`, payload);
    if (
      response?.data?.status >= STATUS_200 &&
      response?.data?.status < STATUS_300
    ) {
      return response?.data?.data;
    } else {
      throw response;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const submitProfileDataService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const response = await axios.post(`${ApiConfig.questionAnswers}`, payload);
    if (
      response?.data?.status >= STATUS_200 &&
      response?.data?.status < STATUS_300
    ) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw response;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
  }
};
export const fetchUserResumeService = async (payload) => {
  try {
    const response = await axios.post(`${ApiConfig.fetchResume}`, payload, {
      responseType: 'blob',
    });
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      return response?.data;
    } else {
      toast.error(errorMessage(response), 2500);
      throw response;
    }
  } catch (e) {
    toast.error("User resume not found");
  }
};

export const uploadUserResumeService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const response = await axios.post(`${ApiConfig.uploadResume}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      dispatch(saved());
      showToastSuccess('Resume uploaded successfully');
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      toast.error('Could not upload resume');
      throw response;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    toast.error('Could not upload resume');
    return false;
  }
};
