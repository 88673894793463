import {
  Box,
  Grid,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import OverallCompetitiveAssessment from './OverallCompetitiveAssessment';
import CompetitorsBlock from './CompetitorsBlock';
import { labels } from '../../../data/constants';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCompetitorService,
  getCompetitorListService,
  linkCompetitorToMarketService,
} from '../../../Services/ResearchServices';
import AddMarketOrCompetitorModal from '../AddMarketOrCompetitorModal';
import { setCompetitorListOption } from '../../../redux/researchSlice';
import { logChanges } from '../../../Services/commentService';
import { saved, saving, couldnotSave, resetStatus } from '../../../redux/saveSlice';

const CompPanel = ({
  compSubCatProfile,
  fetchCompetitorsList,
  overallCompetitiveAssessments = [],
}) => {
  console.log("compSubCatProfile from CompPanel");
  console.log(compSubCatProfile);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddCompModal, setShowAddCompModal] = useState(false);
  const competitorsList = useSelector(
    (state) => state?.researchData?.competitorList
  );
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchCompetitors = async () => {
    try {
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getCompetitorListService(payload);
      if (response) {
        let competitorList = [];

        response?.forEach((item) => {
          let competitor = {
            competitorName: item?.competitor_name,
            competitorId: item?.id,
          };
          competitorList.push(competitor);
        });
        dispatch(setCompetitorListOption(competitorList));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const addCompetitorToMarketSubCat = async (receivedCompetitorId) => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: receivedCompetitorId,
        subcategoryId: compSubCatProfile?.subCatId,
      };
      dispatch(saving());
      const response = await linkCompetitorToMarketService(payload, dispatch);
      if (response) {
        dispatch(saved());
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor added to '${compSubCatProfile?.subCatName}' Sub-Category`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const addCompetitor = async (receivedValue) => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorName: receivedValue,
      };
      const response = await createCompetitorService(payload, dispatch, true);
      if (response) {
        fetchCompetitors();
        addCompetitorToMarketSubCat(response?.id);
        setShowAddCompModal(false);
      }
    } catch (e) {
      console.log(e);
      setShowAddCompModal(false);
    }
  };
  const isCompetitorAlreadyPresent = (receivedCompetitorId) => {
    let isCompetitorPresent = false;
    compSubCatProfile?.competitorsList?.forEach((competitorOption) => {
      if (competitorOption?.id === receivedCompetitorId) {
        isCompetitorPresent = true;
      }
    });
    return isCompetitorPresent;
  };
  return (
    <Box className="border3 borderColorGreen mt1 borderRadius8 padding1">
      <Typography className="mt1 mb1 mr05" variant="dashboardCompHeading5">
        Competitors - {compSubCatProfile?.subCatName}
      </Typography>
      <OverallCompetitiveAssessment
        assessmentList={overallCompetitiveAssessments}
        assessmentData={compSubCatProfile}
        fetchCompetitorsList={fetchCompetitorsList}
        competitorList={compSubCatProfile?.competitorsList}
      />
      <Grid className="mt1_5" container columnSpacing={4} rowSpacing={4}>
        {compSubCatProfile?.competitorsList?.length !== 0 &&
          compSubCatProfile?.competitorsList?.map((competitorOption, index) => (
            <Grid item lg={6} key={'compOption' + index}>
              <CompetitorsBlock
                key={'competitorOption' + index}
                competitorDetail={competitorOption}
                subCatId={compSubCatProfile?.subCatId}
                subCatName={compSubCatProfile?.subCatName}
                fetchCompetitorsList={fetchCompetitorsList}
              />
            </Grid>
          ))
        }
        {/* {compSubCatProfile?.competitorsList?.length === 0 ? null : compSubCatProfile?.competitorsList?.map((competitorOption, index) => (
          <Grid item lg={6} key={'compOption' + index}>
            <CompetitorsBlock
              key={'competitorOption' + index}
              competitorDetail={competitorOption}
              subCatId={compSubCatProfile?.subCatId}
              subCatName={compSubCatProfile?.subCatName}
              fetchCompetitorsList={fetchCompetitorsList}
            />
          </Grid>
        ))} */}
      </Grid>
      <Button
        className="mt1 mb2 textTrasformCapital"
        variant="buttonVariant1"
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        disabled={competitorsList?.length ? false : true}
        aria-expanded={open ? 'true' : undefined}
      >
        {labels.research.competitorTab.addCompetitorBtnLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="mwsc-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className="minWidth300"
      >
        {competitorsList.map((competitorOption, index) => (
          <MenuItem
            key={'subCatOption' + index}
            disabled={isCompetitorAlreadyPresent(
              competitorOption?.competitorId
            )}
            className="customMenuItem"
            onClick={() => {
              addCompetitorToMarketSubCat(competitorOption?.competitorId);
              handleClose();
            }}
          >
            <Typography className="ml1">
              {competitorOption?.competitorName}
            </Typography>
          </MenuItem>
        ))}
        <Divider />
        <Box>
          <Typography variant="disabledText">
            {labels.research.competitorTab.newTextLabel}
          </Typography>
        </Box>
        <MenuItem
          className="customMenuItem"
          onClick={() => {
            setShowAddCompModal(true);
          }}
        >
          <Typography className="ml1">
            {labels.research.competitorTab.createCompetitorBtnLabel}
          </Typography>
        </MenuItem>
      </Menu>
      {showAddCompModal && (
        <AddMarketOrCompetitorModal
          dialogTitle={labels.research.competitorTab.addCompetitorLabel}
          toAdd={'Competitor'}
          handleClose={() => {
            setShowAddCompModal(false);
          }}
          submitMarketDetail={addCompetitor}
        />
      )}
    </Box>
  );
};
export default CompPanel;
