import { createTheme } from '@mui/material';
export const customTheme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
    ].join(','),
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.dashboardSelect1': {
            marginTop: '16px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.dashboardSelect1': {
            height: '36px',
            WebkitTapHighlightColor: 'transparent',
            lineHeight: '34px',
            appearance: 'none',
            resize: 'none',
            boxSizing: 'border-box',
            fontSize: '14px',
            color: 'rgb(0, 0, 0)',
            minHeight: '36px',
            marginTop: '16px',
            paddingLeft: '0px',
            paddingRight: '30px',
            borderRadius: '4px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.fontWeight500': {
            fontWeight: 500,
          },
          '&.mt1': {
            marginTop: '1rem',
          },
          '&.mt05': {
            marginTop: '0.5rem',
          },
          '&.mb05': {
            marginBottom: '0.5rem',
          },
          '&.dashboardLabel1': {
            '& .MuiTypography-root': {
              display: 'inline-block',
              fontSize: '14px',
              fontWeight: '500',
              color: 'rgb(37, 37, 37)',
              lineHeight: '1.55',
            },
            '& .MuiInputLabel-asterisk': {
              color: 'rgb(255, 107, 107)',
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.fontSize18': {
            fontSize: '18px',
          },
          '&.fontSize21': {
            fontSize: '21px',
          },
          '&.fontSize26': {
            fontSize: '26px',
          },
          '&.textCenter': {
            textAlign: 'center',
          },
          '&.fontBold': {
            fontWeight: 'bold',
          },
          '&.mt3': {
            marginTop: '3rem',
          },
          '&.mt2': {
            marginTop: '2rem',
          },
          '&.mb2': {
            marginBottom: '2rem',
          },
          '&.my2': {
            marginBottom: '2rem',
            marginTop: '2rem',
          },
          '&.my1': {
            marginBottom: '1rem',
            marginTop: '1rem',
          },
        },
      },
      variants: [
        {
          props: { variant: 'dashboardCompHeading' },
          style: {
            fontSize: '34px',
            color: 'rgb(20, 0, 110)',
            fontWeight: 700,
            lineHeight: 1.3,
            textDecoration: 'none',
          },
        },
        {
          props: { variant: 'dashboardCompHeading2' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            textDecoration: 'none',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '1.45',
            margin: '0px',
            color: 'rgb(20, 0, 110)',
          },
        },
        {
          props: { variant: 'modalHeading1' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            textDecoration: 'none',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '1.45',
            margin: '0px',
            color: 'rgb(20, 0, 110)',
            padding: '0px 8px !important',
          },
        },
        {
          props: { variant: 'dashboardCompHeading3' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            textDecoration: 'none',
            fontWeight: '700',
            fontSize: '22px',
            lineHeight: '1.4',
            margin: '0px 16px 0px 0px',
            color: 'rgb(20, 0, 110)',
          },
        },
        {
          props: { variant: 'dashboardCompHeading4' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            textDecoration: 'none',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '1.45',
            margin: '0px',
            color: 'rgb(20, 0, 110)',
            padding: '0px 12px !important',
          },
        },
        {
          props: { variant: 'dashboardCompHeading5' },
          style: {
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            color: 'inherit',
            fontSize: '16px',
            lineHeight: '1.55',
            textDecoration: 'none',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'dashboardCompHeading6' },
          style: {
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            color: 'rgb(142, 142, 142)',
            textDecoration: 'none',
            textAlign: 'left',
            marginTop: '5.33333px',
            marginBottom: '5.33333px',
            fontSize: '14px',
            lineHeight: '1',
          },
        },
        {
          props: { variant: 'dashboardCompHeading7' },
          style: {
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            color: 'inherit',
            lineHeight: '1.55',
            textDecoration: 'none',
            fontWeight: '700',
            fontSize: '30px',
          },
        },
        {
          props: { variant: 'dashboardCompHeading8' },
          style: {
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            lineHeight: '1.55',
            textDecoration: 'none',
            fontWeight: '700',
            color: 'rgb(142, 142, 142)',
            fontSize: '12px',
            textAlign: 'center',
          },
        },
        {
          props: { variant: 'tableHeading' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            color: 'inherit',
            fontSize: '14px',
            lineHeight: '1.55',
            textDecoration: 'none',
            fontWeight: '500',
            WebkitBoxFlex: '1',
            flexGrow: '1',
          },
        },
        {
          props: { variant: 'tableCell' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            color: 'inherit',
            fontSize: '16px',
            lineHeight: '1.55',
            textDecoration: 'none',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'successPoint' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            color: 'inherit',
            fontSize: '14px',
            lineHeight: '1.55',
            WebkitTextDecoration: 'none',
            textDecoration: 'none',
          },
        },
        {
          props: { variant: 'successPointLink' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            color: 'rgb(20, 0, 110)',
            fontSize: '14px',
            lineHeight: '1.55',
            WebkitTextDecoration: 'none',
            textDecoration: 'underline',
          },
        },
        {
          props: { variant: 'disabledText' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontSize: '14px',
            border: '0px',
            backgroundColor: 'transparent',
            outline: '0px',
            width: '100%',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            padding: '10px 12px',
            cursor: 'pointer',
            borderRadius: '4px',
            display: 'flex',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            color: 'rgb(181, 181, 181)',
            pointerEvents: 'none',
            userSelect: 'none',
          },
        },
        {
          props: { variant: 'infoText1' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            color: 'inherit',
            fontSize: '14px',
            lineHeight: '1.55',
            textDecoration: 'none',
          },
        },
        {
          props: { variant: 'paraText1' },
          style: {
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            color: 'inherit',
            fontSize: '14px',
            display: 'block',
            lineHeight: '1.55',
            textDecoration: 'none',
            fontWeight: '300',
          },
        },
        {
          props: { variant: 'badgeText1' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontSize: '11px',
            height: '20px',
            lineHeight: '18px',
            textDecoration: 'none',
            padding: '0px 10.6667px',
            boxSizing: 'border-box',
            display: 'inline-flex',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            width: 'auto',
            textTransform: 'uppercase',
            borderRadius: '16px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'inherit',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(242, 251, 245)',
            color: 'rgb(100, 190, 131)',
            border: '1px solid transparent',
            marginRight: '10px',
          },
        },
        {
          props: { variant: 'badgeText2' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontSize: '11px',
            height: '20px',
            lineHeight: '18px',
            textDecoration: 'none',
            padding: '0px 8px',
            boxSizing: 'border-box',
            display: 'inline-flex',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            width: 'auto',
            textTransform: 'uppercase',
            borderRadius: '5px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'inherit',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(253, 245, 225)',
            color: 'rgb(203, 148, 11)',
            float: 'right',
            border: '1px solid transparent',
            // marginRight: '10px',
          },
        },
        {
          props: { variant: 'customStyle1' },
          style: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            whiteSpace: 'break-spaces',
          },
        },
        {
          props: { variant: 'customStyle2' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontSize: '9px',
            lineHeight: '14px',
            textDecoration: 'none',
            padding: '0px 6.66667px',

            width: 'auto',
            textTransform: 'uppercase',
            borderRadius: '4px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'inherit',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(219, 246, 229)',
            color: 'rgb(64, 146, 92)',
            border: '1px solid transparent',
            marginLeft: '4px',
          },
        },
        {
          props: { variant: 'customStyle3' },
          style: {
            fontSize: '13px',
            height: '26px',
            lineHeight: '24px',
            textDecoration: 'none',
            padding: '0px 13.3333px',
            boxSizing: 'border-box',
            display: 'inline-flex',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            width: 'auto',
            textTransform: 'uppercase',
            borderRadius: '4px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'inherit',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(219, 246, 229)',
            color: 'rgb(64, 146, 92)',
            border: '1px solid transparent',
            marginBottom: '7px',
          },
        },
        {
          props: { variant: 'customStyle4' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontSize: '11px',
            lineHeight: '14px',
            height: '15px',
            textDecoration: 'none',
            padding: '4px 15px',
            width: '100%',
            textTransform: 'capitalize',
            borderRadius: '4px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'inherit',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(226, 226, 226)',
            color: 'rgb(77, 13, 245)',
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'customStyle5' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontSize: '11px',
            lineHeight: '14px',
            height: '15px',
            textDecoration: 'none',
            padding: '4px 15px',
            // width: '100%',
            textTransform: 'capitalize',
            borderRadius: '4px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'inherit',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(226, 226, 226)',
            color: 'rgb(77, 13, 245)',
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'errorText' },
          style: {
            color: 'rgb(255, 107, 107)',
          },
        },
        {
          props: { variant: 'greyText' },
          style: {
            fontSize: '14px',
            color: 'rgb(181, 181, 181)',
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.textFieldType1': {
            margin: '15px 0px 5px 0px',
            '& .MuiInputBase-root': {
              height: '36px',
              lineHeight: '34px',
              fontSize: '14px',
              color: 'rgb(0, 0, 0)',
              minHeight: '36px',
              paddingLeft: '0px',
              paddingRight: '0px',
              borderRadius: '4px',
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              backgroundColor: 'transparent !important',
              color: '#fff !important',
              appearance: 'textfield !important',
            },
          },
          '&.textFieldType2': {
            margin: '15px 0px 5px 0px',
            '& .MuiInputBase-root': {
              minHeight: '40px',
              lineHeight: '1.55',
              fontSize: '14px',
              color: 'rgb(0, 0, 0)',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderRadius: '4px',
              paddingTop: '0px',
              paddingBottom: '0px',
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              backgroundColor: 'transparent !important',
              color: '#fff !important',
              appearance: 'textfield !important',
            },
          },
          '&.textFieldType3': {
            '& .MuiInputBase-root': {
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              WebkitTapHighlightColor: 'transparent',
              lineHeight: '34px',
              appearance: 'none',
              resize: 'none',
              boxSizing: 'border-box',
              fontSize: '14px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              display: 'block',
              textAlign: 'left',
              minHeight: '36px',
              padding: '0px 12px',
              borderRadius: '4px',
              backgroundColor: 'rgb(255, 255, 255)',
              transition: 'border-color 100ms ease 0s',
            },
            '& .MuiInputBase-input': {
              padding: 0,
              minHeight: '36px',
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              backgroundColor: 'transparent !important',
              color: '#fff !important',
              appearance: 'textfield !important',
            },
          },
          '&.textFieldType4': {
            margin: '15px 0px 5px 0px',
            '& .MuiInputBase-root': {
              minHeight: '40px',
              lineHeight: '1.55',
              fontSize: '14px',
              color: 'rgb(0, 0, 0)',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderRadius: '4px',
              paddingTop: '0px',
              paddingBottom: '0px',
            },
            '& .MuiOutlinedInput-input': {
              padding: '8px 10px',
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              backgroundColor: 'transparent !important',
              color: '#fff !important',
              appearance: 'textfield !important',
            },
          },
          '&.textFieldType5': {
            margin: '15px 0px 5px 0px',
            '& .MuiInputBase-root': {
              minHeight: '40px',
              lineHeight: '1.55',
              fontSize: '14px',
              color: 'rgb(0, 0, 0)',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderRadius: '4px',
              paddingTop: '0px',
              paddingBottom: '0px',
            },
            '& .MuiOutlinedInput-input': {
              padding: '8px 10px',
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              backgroundColor: 'transparent !important',
              color: '#fff !important',
              appearance: 'textfield !important',
            },
          },
          '&.textFieldType6': {
            '& .MuiInputBase-root': {
              height: '36px',
              lineHeight: '34px',
              fontSize: '14px',
              color: 'rgb(0, 0, 0)',
              minHeight: '36px',
              paddingLeft: '0px',
              paddingRight: '0px',
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              backgroundColor: 'transparent !important',
              color: '#fff !important',
              appearance: 'textfield !important',
            },
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .customIndicator1': {
            backgroundColor: 'rgb(20, 0, 110)',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.dashboardTab1': {
            fontSize: '14px',
            textTransform: 'capitalize',
            '&.Mui-selected': {
              color: 'rgb(0, 0, 0)',
            },
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'buttonVariant1' },
          style: {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'center',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(25, 62, 135)',
            color: 'rgb(245, 245, 245)',
            '&:hover': {
              color: 'rgb(245, 245, 245)',
              backgroundColor: 'rgb(16,39,86)',
              border: '1px solid rgb(16,39,86)',
            },
            '&:disabled': {
              color: 'rgb(194, 194, 194)',
            },
            '.width100': {
              width: '100%',
            },
          },
        },
        {
          props: { variant: 'buttonVariant2' },
          style: {
            padding: '0px !important',
            appearance: 'none',
            textAlign: 'center',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(25, 62, 135)',
            color: 'rgb(245, 245, 245)',
            '&:hover': {
              color: 'rgb(245, 245, 245)',
              backgroundColor: 'rgb(16,39,86)',
              border: '1px solid rgb(16,39,86)',
            },
            '&:disabled': {
              color: 'rgb(194, 194, 194)',
            },
            '.width100': {
              width: '100%',
            },
          },
        },
        {
          props: { variant: 'purpleBtnLight' },
          style: {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            WebkitTapHighlightColor: 'transparent',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(232, 225, 250)',
            color: 'rgb(77, 13, 245)',
          },
        },
        {
          props: { variant: 'blueBtnLight' },
          style: {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none !important',
            boxSizing: 'border-box',
            height: '36px',
            WebkitTapHighlightColor: 'transparent',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(232, 225, 250)',
            color: 'rgb(77, 13, 245)',
          },
        },
        {
          props: { variant: 'purpleBtnDark' },
          style: {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'center',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            WebkitTapHighlightColor: 'transparent',
            display: 'block',
            width: '100%',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(77, 13, 245)',
            color: 'rgb(255, 255, 255)',
            '&:hover': {
              color: 'rgb(77, 13, 245)',
              border: '1px solid rgb(77, 13, 245)',
            },
          },
        },
        {
          props: { variant: 'purpleBtnTransparent' },
          style: {
            padding: '0px 8px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '26px',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'transparent',
            color: 'rgb(77, 13, 245)',
          },
        },
        {
          props: { variant: 'textButton1' },
          style: {
            padding: '0px 8px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '26px',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'transparent',
            color: 'rgb(20, 0, 110)',
            marginLeft: 'auto',
            marginBottom: '-1px',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: 'rgb(232, 225, 250)',
            },
            '&::after': {
              content: '">"',
              display: 'inine-block',
              marginLeft: '5px',
            },
          },
        },
        {
          props: { variant: 'textButton2' },
          style: {
            padding: '0px 8px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '26px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'transparent',
            color: 'rgb(77, 13, 245)',
            textTransform: 'capitalize',
            marginLeft: 'auto',
            marginBottom: '-1px',
          },
        },
        {
          props: { variant: 'stackButton' },
          style: {
            padding: '0px 8px',
            appearance: 'none',
            textAlign: 'center',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '26px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'transparent',
            color: 'rgb(77, 13, 245)',
            textTransform: 'capitalize',
            marginLeft: 'auto',
            marginBottom: '-1px',
            '&.active': {
              backgroundColor: 'rgb(255,255,255)',
            },
          },
        },
        {
          props: { variant: 'textButton3' },
          style: {
            padding: '7px 8px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '26px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-flex',
            alignItems: 'center',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid rgb(25, 62, 135)',
            backgroundColor: 'transparent',
            color: 'rgb(25, 62, 135)',
            textTransform: 'capitalize',
            marginLeft: 'auto',
            marginBottom: '-1px',
          },
        },
        {
          props: { variant: 'textButton4' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontSize: '9px',
            height: '16px',
            lineHeight: '14px',
            textDecoration: 'none',
            padding: '0px 6.66667px',
            boxSizing: 'border-box',
            display: 'inline-flex',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            width: 'auto',
            textTransform: 'uppercase',
            borderRadius: '4px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(244, 226, 241)',
            color: 'rgb(139, 51, 128)',
            border: '1px solid transparent',
            marginBottom: '0px',
          },
        },
        {
          props: { variant: 'textButton5' },
          style: {
            WebkitTapHighlightColor: 'transparent',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontSize: '9px',
            height: '16px',
            lineHeight: '14px',
            textDecoration: 'none',
            padding: '10px 15px',
            boxSizing: 'border-box',
            display: 'inline-flex',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            width: 'auto',
            textTransform: 'none',
            borderRadius: '4px',
            fontWeight: '700',
            letterSpacing: '0.25px',
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'rgb(236, 236, 236)',
            color: 'rgb(20, 0, 110)',
            border: '1px solid transparent',
            marginBottom: '0px',
            minWidth: '100%',
            justifyContent: 'left',
          },
        },
        {
          props: { variant: 'textButton6' },
          style: {
            padding: '17px 8px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '26px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-flex',
            alignItems: 'center',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid rgb(25, 62, 135)',
            backgroundColor: 'transparent',
            color: 'rgb(0, 0, 0)',
            borderColor: 'rgb(212, 212, 212)',
            textTransform: 'capitalize',
            marginLeft: 'auto',
            marginBottom: '-1px',
          },
        },
        {
          props: { variant: 'warningButton' },
          style: {
            textTransform: 'none',
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'center',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(255, 107, 107)',
            color: 'rgb(245, 245, 245)',
            '&:hover': {
              color: 'rgb(245, 245, 245)',
              backgroundColor: 'rgb(250, 82, 82)',
              border: '1px solid rgb(250, 82, 82)',
            },
            '&:disabled': {
              backgroundColor: 'rgb(194, 194, 194)',
            },
            '.width100': {
              width: '100%',
            },
          },
        },
        {
          props: { variant: 'menuButton' },
          style: {
            color: 'black',
            border: '1px solid rgb(212, 212, 212)',
            borderRadius: '4px',
            fontSize: '12px',
            textTransform: 'none',
            paddingInline: '9px',
            minWidth: '127px',
            display: 'flex',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
              color: 'rgb(212, 212, 212)',
            },
          },
        },
        {
          props: { variant: 'textLink' },
          style: {
            padding: '0px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'underline',
            boxSizing: 'border-box',
            height: 'auto',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-block',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '700',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: 'none',
            backgroundColor: 'transparent',
            color: 'rgb(77, 13, 245)',
            textTransform: 'capitalize',
            marginLeft: 'auto',
            marginBottom: '0px',
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.customMenuItem': {
            WebkitTapHighlightColor: 'transparent',
            fontSize: '14px',
            border: '0px',
            backgroundColor: 'transparent',
            outline: '0px',
            width: '100%',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            padding: '10px 12px',
            cursor: 'pointer',
            borderRadius: '4px',
            color: 'rgb(0, 0, 0)',
            display: 'flex',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            minWidth: '200px',
            '&:hover': {
              backgroundColor: 'rgb(243,243,243)',
            },
          },
          '&.customMenuItem2': {
            maxWidth: '400px',
            marginBottom: '0.5rem',
            alignItems: 'flex-start',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.centeredCell': {
            textAlign: 'center',
          },
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'purpleBtnLight' },
          style: {
            padding: '0px 15px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '28px',
            WebkitTapHighlightColor: 'transparent',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(232, 225, 250)',
            color: 'rgb(77, 13, 245)',
            '&:hover': {
              backgroundColor: 'rgba(195, 177, 244, 0.65)',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '&.primaryColor1': {
            color: 'rgb(20, 0, 110)',
          },
          '&.iconButtonType1': {
            padding: '0px 3px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '30px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-flex',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '12px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            marginLeft: '5px',
            marginRight: '5px',
            alignItems: 'center',
            color: 'rgb(25, 62, 135)',
            '&:disabled': {
              backgroundColor: 'rgba(194, 194, 194, 0.3)',
            },
          },
          '&.iconButtonType2': {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-flex',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(25, 62, 135)',
            color: 'rgb(255,255,255)',
            marginRight: '12px',
            alignItems: 'center',
            '&:disabled': {
              color: 'rgb(194, 194, 194)',
            },
          },
          '&.iconButtonType3': {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-flex',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(25, 62, 135)',
            color: 'rgb(255,255,255)',
            marginRight: '0px',
            alignItems: 'center',
          },
          '&.iconButtonType4': {
            padding: '0px 8px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '26px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-flex',
            alignItems: 'center',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(25, 62, 135)',
            color: 'rgb(255, 255, 255)',
            '&:disabled': {
              backgroundColor: 'rgb(194, 194, 194)',
            },
          },
          '&.iconButtonType5': {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid rgb(212, 212, 212)',
            backgroundColor: 'rgb(255, 255, 255)',
            color: 'rgb(0, 0, 0)',
            '&:hover': {
              backgroundColor: 'rgb(249, 249, 249)',
            },
          },
          '&.iconButtonType6': {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'inline-flex',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            backgroundColor: 'rgb(25, 62, 135)',
            color: 'rgb(255,255,255)',
            marginRight: '12px',
            alignItems: 'center',
            justifyItems: 'right !important',
            '&:disabled': {
              color: 'rgb(194, 194, 194)',
            },
          },
          '&.iconButtonType7': {
            padding: '0px 18px',
            appearance: 'none',
            textAlign: 'left',
            textDecoration: 'none',
            boxSizing: 'border-box',
            height: '36px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            WebkitTapHighlightColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            width: 'auto',
            borderRadius: '4px',
            fontWeight: '600',
            position: 'relative',
            lineHeight: '1',
            fontSize: '14px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid rgb(212, 212, 212)',
            backgroundColor: 'rgb(255, 255, 255)',
            color: 'rgb(0, 0, 0)',
            justifyItems: 'right !important',
            '&:hover': {
              backgroundColor: 'rgb(249, 249, 249)',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.textCenter': {
            textAlign: 'center',
          },
          '&.mt2': {
            marginTop: '2rem',
          },
          '&.mt1': {
            marginTop: '1rem',
          },
          '&.mb1': {
            marginBottom: '1rem',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '&.listItemText1': {
            '& .MuiTypography-root': {
              WebkitTapHighlightColor: 'transparent',
              color: 'inherit',
              fontSize: '14px',
              lineHeight: '1.55',
              textDecoration: 'none',
              fontWeight: '400',
              textWrap: 'wrap',
              paddingLeft: '2px',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: 'rgb(20, 0, 110)',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: 'rgb(20, 0, 110)',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.customAccordion1': {
            backgroundColor: 'transparent',
            borderRadius: '8px',
            border: '1px solid',
            borderColor: 'rgb(212, 212, 212)',
            boxShadow: 'none',
            '&::before': {
              backgroundColor: 'transparent',
            },
            '&.Mui-expanded': {
              backgroundColor: 'rgb(243, 243, 243)',
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.customSlider': {
            '& .MuiSlider-rail': {
              borderRadius: '32px',
              backgroundColor: 'rgb(160, 160, 160)',
              height: '8px',
            },
            '& .MuiSlider-track': {
              height: '8px',
            },
            '& .MuiSlider-mark': {
              boxSizing: 'border-box',
              border: '1px solid rgb(215, 215, 215)',
              height: '6px',
              width: '6px',
              borderRadius: '50%',
              backgroundColor: 'rgb(255, 255, 255)',
              opacity: 1,
            },
            '& .MuiSlider-markLabel': {
              fontSize: '14px',
              color: 'rgb(142, 142, 142)',
              whiteSpace: 'nowrap',
            },
            '& .MuiSlider-thumb': {
              backgroundColor: 'rgb(255, 255, 255)',
              border: '3px solid #1976d2',
              height: '15px',
              width: '15px',
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '&.customAlignment': {
            '&.MuiDialog-root': {
              left: '17%',
            },
          },
        },
      },
    },
  },
});
