import React from 'react';
import './App.css';
import { AuthProvider } from './Context/AuthProvider';
import { Provider } from 'react-redux';
import AuthRoute from './Route/AuthRoute';
import { store, persistor } from './redux/store';
import './AxiosInterceptor';
import { ThemeProvider } from '@mui/material';
import { customTheme } from './Theme/custom-theme';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <ThemeProvider theme={customTheme}>
            <AuthRoute />
          </ThemeProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;