import { Box, Typography } from '@mui/material';
import { labels, proModalsContent } from '../../../data/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import TypingEffectTextField from '../../IdeaDetails/TypingEffectTextField';
import { updateCompDiffService } from '../../../Services/ResearchServices';
import { aiAutofillService } from '../../../Services/ideaDetailService';
import { saved, saving, couldnotSave, resetStatus } from '../../../redux/saveSlice';
import { logChanges } from '../../../Services/commentService';
import ProRequiredModal from '../../CommonComponents/proRequiredModal/proRequiredModal';

const CompDiffBlock = () => {
  const dispatch = useDispatch();
  const selectedIdea = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const researchListForSelectedIdea = useSelector(
    (state) => state?.researchData?.researchListForSelectedIdea
  );
  const [selectedIdeaDetails, setSelectedIdeaDetails] = useState(null);
  const [selectedIdeaDetailsCopy, setSelectedIdeaDetailsCopy] = useState(null);
  const [questionLoader, setQuestionLoader] = useState({
    loading: false,
    forQuestion: null,
  });
  const [proRequiredModalShow, setProRequiredModalShow] = useState(false);

  useEffect(() => {
    if (!selectedIdea || !researchListForSelectedIdea) {
      setSelectedIdeaDetails(null);
    }
  }, [selectedIdea, researchListForSelectedIdea]);

  useEffect(() => {
    if (selectedIdea && researchListForSelectedIdea) {
      let details = {
        question: 'Competitive Differentiation',
        ideaId: selectedIdea?.id,
        isSynced: true,
        aiAutofill: true,
        isDependencyFullfilled: !researchListForSelectedIdea ? false : true,
        dependentText:
          'To use AI Autofill, first complete the Market, Sub-Category, and Competitors above for this idea',
        answer: researchListForSelectedIdea[0]?.competitive_differentiation,
        placeholder:
          labels.research.ideaTab.competitiveDifferentiationBlock
            .compDiffPlaceholder,
        ...selectedIdea,
        ...researchListForSelectedIdea,
        type: 'textFieldMulti',
        autoFillPro: false,
      };
      setSelectedIdeaDetails(JSON.parse(JSON.stringify(details)));
      setSelectedIdeaDetailsCopy(JSON.parse(JSON.stringify(details)));
    }
  }, [selectedIdea, researchListForSelectedIdea]);
  const handleDataChange = (ideaId, receivedValue) => {
    setSelectedIdeaDetails((prevState) => {
      return {
        ...prevState,
        answer: receivedValue,
      };
    });
  };
  const handleAnswerSubmit = async (ideaId, receivedValue) => {
    try {
      if (selectedIdeaDetailsCopy?.answer === receivedValue) {
        return;
      }
      let payload = {
        researchIdeaDetailsId: researchListForSelectedIdea[0]?.id,
        CompetitiveDifferentiation: receivedValue,
      };
      dispatch(saving());
      const reponse = await updateCompDiffService(payload, dispatch);
      if (reponse) {
        dispatch(saved());
        setSelectedIdeaDetailsCopy((prevState) => {
          return {
            ...prevState,
            answer: receivedValue,
          };
        });
        let changeLogPayload = {
          changeDetail: `Idea '${selectedIdeaDetails.name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  const handleAiCall = async (questionId, isPro, question) => {
    try {
      let payload = {
        ideaId: selectedIdeaDetails?.id,
        autofill_field: question,
        isPro: isPro,
      };
      const response = await aiAutofillService(payload, dispatch);
      if (response) {
        handleDataChange(questionId, response);
        handleAnswerSubmit(questionId, response);
        return response;
      }
      if (!response) {
        setProRequiredModalShow(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <ProRequiredModal open={proRequiredModalShow} setOpen={setProRequiredModalShow} content={proModalsContent.aiAutofill} />
      <Box className="border3 borderColorGrey3 mt1 mb2 borderRadius8 padding1">
        {!researchListForSelectedIdea && (
          <>
            <Typography className="mt1 mb1 mr05" variant="dashboardCompHeading5">
              {
                labels.research.ideaTab.competitiveDifferentiationBlock
                  .compDiffHeading
              }
            </Typography>
            <Typography>
              {labels.research.ideaTab.marketSelectBlock.marketSelectPlaceholder}
            </Typography>
          </>
        )}
        {selectedIdeaDetails && (
          <TypingEffectTextField
            tabItem={selectedIdeaDetails}
            ideaId={selectedIdea?.id}
            handleChange={handleDataChange}
            handleBlur={handleAnswerSubmit}
            aiAutoFill={handleAiCall}
            questionLoader={questionLoader}
            setQuestionLoader={setQuestionLoader}
          />
        )}
      </Box>
    </>
  );
};
export default CompDiffBlock;
