import { Box, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import IdeaSelectorComp from '../BusinessPlan/ideaSelectorComp';
import MarketSelectBlock from './Ideas/MarketSelectBlock';
import CompetitorsPanel from './Ideas/CompetitorsPanel';
import CompDiffBlock from './Ideas/CompDiffBlock';

const IdeaTabContent = ({
  setSelectedTab = () => { },
  AddMarket = () => { },
}) => {
  return (
    <Box>
      <Typography className="mt1 mb1">
        {labels.research.ideaTab.subHeading}
      </Typography>
      <IdeaSelectorComp />
      <MarketSelectBlock
        AddMarket={AddMarket}
        setSelectedTab={setSelectedTab}
      />
      <CompetitorsPanel />
      <CompDiffBlock />
    </Box>
  );
};
export default IdeaTabContent;
