import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getSubCatsWithCompsService } from '../../../Services/ResearchServices';
import CompPanel from './CompPanel';
import { Box, Typography } from '@mui/material';
import { labels } from '../../../data/constants';
import { setSelectedMarketSubCatsForResearch } from '../../../redux/researchSlice';
const CompetitorsPanel = () => {
  const dispatch = useDispatch();
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const selectedIdea = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const selectedMarketOption = useSelector(
    (state) => state?.researchData?.selectedMarketOption
  );
  const marketWithCatDtls = useSelector(
    (state) => state?.researchData?.marketAndSubCats
  );
  const researchListForSelectedIdea = useSelector(
    (state) => state?.researchData?.researchListForSelectedIdea
  );
  const selectedMarketSubCatsForResearch = useSelector(
    (state) => state?.researchData?.selectedMarketSubCatsForResearch
  );
  let selectedMarketWithSubCats = marketWithCatDtls?.find(market => market.market_id === selectedMarketOption?.id);
  const [competitorsList, setCompetitorsList] = useState(null);
  const [ocaList, setOcaList] = useState([]);
  const fetchSubCatName = (subCatId) => {
    let subCatName = '';
    marketWithCatDtls?.forEach((marketOpt) => {
      marketOpt?.sub_categories?.forEach((subCatOpt) => {
        if (subCatOpt?.sub_category_id === subCatId) {
          subCatName = subCatOpt?.sub_category_name;
        }
      });
    });
    return subCatName;
  };
  const fetchCompetitorsList = async () => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        ideaId: selectedIdea?.id,
        marketId: selectedMarketOption?.id,
      };
      const response = await getSubCatsWithCompsService(payload);
      if (response) {
        let subCatCompDtls = [];
        // let activeMarketSubCats = selectedMarketOption?.subcategory_id;
        selectedMarketWithSubCats?.sub_categories?.forEach((marketSubCat) => {
          // let subCatName = fetchSubCatName(marketSubCat);
          let subCat = {
            subCatName: marketSubCat?.sub_category_name,
            subCatId: marketSubCat?.sub_category_id,
            competitorsList: [],
          };
          response?.competitorsDetails?.forEach((compOption) => {
            compOption?.sub_category?.forEach((subCatOption) => {
              if (subCatOption?.id === marketSubCat?.sub_category_id) {
                let compDetail = { ...compOption };
                compDetail['sub_category'] = subCatOption;
                subCat.competitorsList.push(compDetail);
                subCat['market_id'] = subCatOption?.market_id;
                subCat['overallCompetitiveAssessmentid'] =
                  subCatOption?.overallCompetitiveAssessmentid;
              }
            });
          });
          dispatch(setSelectedMarketSubCatsForResearch(response?.researchIdeaDetails?.subcategory_id));
          subCatCompDtls.push(subCat);
        });
        console.log("subCatCompDtls from CompetitorsPanel");
        console.log(subCatCompDtls);
        setCompetitorsList([...subCatCompDtls]);
        setOcaList(response?.overallCompetitiveAssessments);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setCompetitorsList(null);
    if (selectedMarketOption?.id) {
      fetchCompetitorsList();
    }
  }, [selectedMarketOption, researchListForSelectedIdea, marketWithCatDtls]);
  return (
    <>
      {selectedMarketSubCatsForResearch?.length && selectedMarketSubCatsForResearch?.length !== 0 ? (
        competitorsList?.map((subCatCompOpt, index) => (
          selectedMarketSubCatsForResearch?.includes(subCatCompOpt?.subCatId) && (
            <CompPanel
              key={'subCatCompOpt' + index}
              compSubCatProfile={subCatCompOpt}
              fetchCompetitorsList={fetchCompetitorsList}
              overallCompetitiveAssessments={ocaList}
            />
          )
        ))
      ) : (
        <Box className="border3 borderColorGreen mt1 borderRadius8 padding1">
          <Typography className="mt1 mb1 mr05" variant="dashboardCompHeading5">
            {labels.research.ideaTab.competitiveDifferentiationBlock.heading}
          </Typography>
          <Typography>{labels.research.ideaTab.compPanel.para1}</Typography>
        </Box>
      )}
    </>

  );
};
export default CompetitorsPanel;
