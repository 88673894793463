import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import { labels, profileScore_70 } from '../../data/constants';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useEffect, useState } from 'react';
import {
  generateCareerPathways,
  getCareerPathways,
} from '../../Services/CareerPathwayService';
import { useSelector } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { fetchUserResumeService } from '../../Services/ProfileServices';
const CareerPathwayComp = () => {
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.selectedWorkspace
  );
  const userAllWorkspaces = useSelector(
    (state) => state?.loginUserData?.user?.allWorkspaces
  );
  const isGuest = userWorkspace?.role === 'guest' ? true : false;
  const [loading, setLoading] = useState(false);
  const [pathwayData, setPathwayData] = useState('');
  const [noData, setNoData] = useState('');
  const [workspaceProfileScore, setWorkspaceProfileScore] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (userWorkspace) {
      getPathways();
    }
  }, [userWorkspace]);
  useEffect(() => {
    if (userAllWorkspaces) {
      getWorkspaceProfileScore();
    }
  }, [userWorkspace, userAllWorkspaces]);
  const getWorkspaceProfileScore = () => {
    let profileScore;
    userAllWorkspaces.forEach((workspaceOption) => {
      if (workspaceOption?.id === userWorkspace?.id) {
        profileScore = workspaceOption?.profileScore;
      }
    });
    setWorkspaceProfileScore(profileScore);
  };
  const getPathways = async () => {
    const payload = {
      workspaceid: userWorkspace?.id,
    };
    const response = await getCareerPathways(payload);
    if (!response?.data?.careerPath) {
      setPathwayData('');
      setNoData(response?.message);
    } else {
      setNoData('');
      setPathwayData(response?.data?.careerPath);
    }
  };
  const generatePathways = async () => {
    setLoading(true);
    const payload = {
      workspaceid: userWorkspace?.id,
    };
    await generateCareerPathways(payload);
    await getPathways();
    setLoading(false);
  };
  const fetchResume = async () => {
    try {
      setLoader(true);
      const payload = {
        workspaceid: userWorkspace.id,
      };
      const response = await fetchUserResumeService(payload);
      if (response) {
        setLoader(false);
        const pdfBlob = response;
        const url = window.URL.createObjectURL(new Blob([pdfBlob]));
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'yourResume.pdf');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      } else {
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };
  return (
    <>
      <Box mt={2} mr={2} pb={3}>
        <Grid container>
          <Grid item lg={10} display={'flex'} alignItems={'center'}>
            <Typography ml={2} component={'h1'} variant="dashboardCompHeading">
              {labels.careerPathways.title}
            </Typography>

            {!loading ? (
              <IconButton
                disabled={isGuest || workspaceProfileScore < profileScore_70}
                onClick={generatePathways}
                aria-label={
                  pathwayData
                    ? labels.careerPathways.regeneratePathways
                    : labels.careerPathways.generatePathways
                }
                className="iconButtonType2 ml1"
              >
                <AutorenewIcon className="mr05" />
                {pathwayData
                  ? labels.careerPathways.regeneratePathways
                  : labels.careerPathways.generatePathways}
              </IconButton>
            ) : (
              <Box className="displayFlex alignItemsCenter ml05">
                <CircularProgress size={'1rem'} />
                <Typography className="ml05" variant="paraText1">
                  {pathwayData
                    ? labels.careerPathways.regeneratePathways
                    : labels.careerPathways.generatePathways}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            lg={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'end'}
          >
            <Tooltip
              title={
                pathwayData && !isGuest
                  ? labels.profile.resumeBtn
                  : isGuest
                  ? labels.profile.accessDenied
                  : labels.profile.resumeBtnDisabled
              }
            >
              <Box>
                <Button
                  disabled={pathwayData && loader === false ? false : true}
                  className="textTransformNone"
                  variant="textButton3"
                  onClick={fetchResume}
                >
                  {labels.profile.resumeBtn}
                  {loader && (
                    <CircularProgress className="ml05" size={'1rem'} />
                  )}
                </Button>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
        <Divider className="mt1"></Divider>
        {noData && !isGuest && (
          <Typography
            component={'h2'}
            className="displayFlex alignItemsCenter justifyCenter mt5 fontWeightBold fontSize24"
            gap={1}
          >
            <ErrorOutlineIcon /> {noData}{' '}
            {labels.careerPathways.noDataFoundLabel}
          </Typography>
        )}
        {noData && isGuest && (
          <Typography
            component={'h2'}
            className="displayFlex alignItemsCenter justifyCenter mt5 fontWeightBold fontSize24"
            gap={1}
          >
            <ErrorOutlineIcon /> {labels.careerPathways.guestAccessDeniedLabel}
          </Typography>
        )}
        {pathwayData && (
          <Typography
            className="pb3 mt2 ml2 mr2"
            dangerouslySetInnerHTML={{ __html: pathwayData }}
          ></Typography>
        )}
      </Box>
    </>
  );
};

export default CareerPathwayComp;
