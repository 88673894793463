import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { labels } from "../../../data/constants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { saving, saved, couldnotSave, resetStatus } from "../../../redux/saveSlice";
import { makePayment } from "../../../Services/PaymentServices";
import { useNavigate } from "react-router-dom";
import PlanSelectionFormControl from '../../Custom Components/PlanSelectionFormControl/PlanSelectionFormControl';

const ProRequiredModal = ({ open, setOpen, content }) => {
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const handleClose = () => {
    setInputValue('');
    setOpen(false);
  };
  const [selectedPlanId, setSelectedPlanId] = useState();
  const navigate = useNavigate();

  const upgradePlan = async () => {
    try {
      let payload = {
        planId: selectedPlanId,
      };
      dispatch(saving());
      const response = await makePayment(payload, dispatch);
      if (response) {
        dispatch(saved());
        window.open(response?.data?.url, '_self');
      }
    } catch (e) {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      console.log(e);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box mb={2} width="100%" className="justifySpaceBetween displayFlex m0">
          <Typography variant="dashboardCompHeading2">
            {content.heading}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant={'customStyle3'}>
          {labels.accounts.proLabel}
        </Typography>
        <Typography className="colorBlack pb1">
          {content.noteText1}
        </Typography>
        <Typography className="colorBlack pb1">
          {content.noteText2}
        </Typography>
        <PlanSelectionFormControl
          selectedPlanId={selectedPlanId}
          setSelectedPlanId={setSelectedPlanId}
        />
        <Box mt={2}>
          <Button className='textTrasformCapital width100 mb1' variant='purpleBtnLight'
            onClick={() => {
              navigate('/pro');
              setOpen(false);
            }}>
            {labels.accounts.learnMore}
          </Button>
          <Button variant="buttonVariant1" className="textTrasformCapital width100" onClick={upgradePlan}>
            {labels.accounts.upgradeNow}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProRequiredModal;
