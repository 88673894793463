import {
    Box,
    Typography,
} from '@mui/material';
import { labels } from '../../data/constants';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const PaymentFailureComp = () => {
    return (
        <>
            <Box mt={2} mr={2} pb={3}>
                <Typography
                    component={'h2'}
                    mt={5}
                    fontSize={24}
                    fontWeight={'bold'}
                    textAlign={'center'}
                >
                    {labels.sorry}
                </Typography>
                <CancelOutlinedIcon className='enlargedIcon m8x34x4x49' fontSize='large' sx={{ color: '#bb0000' }} />
                <Typography
                    component={'h2'}
                    mt={5}
                    // fontSize={24}
                    // fontWeight={'bold'}
                    textAlign={'center'}
                >
                    {labels.paymentFail}
                </Typography>
            </Box>
        </>
    );
};

export default PaymentFailureComp;
