import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import StudentsTabBlock from './StudentsTabBlock';
import CustomCircularProgress from '../Custom Components/CustomCircularProgress/CustomCircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
const StudentsTabContent = ({
  tabContent,
  submitAnswer = () => {},
  tabDataHandler = () => {},
  aiSuggestionData = null, // suggestion data generated as per tab by ai
  aiSuggestionHandler = () => {}, // function to be called for ai suggestion as per tab
  asdFetcher = () => {}, //ai suggested data fetcher,
}) => {
  const [isGenerating, setIsGenerating] = useState(false); // to show generating suggestions label on buton
  const [isFetching, setIsFetching] = useState(false); // to show 'fetching suggestions' label on buton
  const getAISuggestions = async () => {
    try {
      setIsGenerating(true);
      const response = await aiSuggestionHandler();
      if (response) {
        setIsGenerating(false);
        setIsFetching(true);
        await asdFetcher();
        setIsFetching(false);
      } else {
        setIsFetching(false);
        setIsGenerating(false);
      }
    } catch (e) {
      setIsFetching(false);
      setIsGenerating(false);
    }
  };

  return (
    <Box mt={3} ml={2} sx={{ borderBottom: 1, borderColor: 'divider' }} pb={3}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography
          component={'h3'}
          variant="dashboardCompHeading"
          className="fontSize26"
          mr={2}
        >
          {tabContent?.tabName}
        </Typography>
        {tabContent?.completionPercentage !== undefined ? (
          <CustomCircularProgress
            value={tabContent?.completionPercentage || 0}
          />
        ) : (
          ''
        )}
      </Box>
      <StudentsTabBlock
        handleAnswerSubmit={submitAnswer}
        blockDetail={[...(tabContent?.questions || [])].sort(
          (a, b) => a?.id - b?.id
        )}
        dataHandler={tabDataHandler}
      />
      <Box className="displayFlex justifyCenter">
        <IconButton
          onClick={getAISuggestions}
          className={
            tabContent?.completionPercentage === 100
              ? 'iconButtonType6 mr0'
              : 'iconButtonType7 mr0'
          }
          disabled={
            (tabContent?.completionPercentage === 100 ? false : true) ||
            isFetching ||
            isGenerating
          }
        >
          {isGenerating || isFetching ? (
            <CircularProgress className="mr05" size={'1rem'} />
          ) : (
            <EditIcon fontSize="small" className="mr05" />
          )}
          {isGenerating
            ? 'Generating Suggestions'
            : isFetching
            ? 'Fetching Suggestions'
            : 'AI Suggestions'}
        </IconButton>
      </Box>
      {aiSuggestionData && (
        <Box
          className="mt1"
          component={'div'}
          dangerouslySetInnerHTML={{ __html: aiSuggestionData }}
        ></Box>
      )}
    </Box>
  );
};

export default StudentsTabContent;
