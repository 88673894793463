import axios from 'axios';
import { saved, saving, couldnotSave, resetStatus } from '../redux/saveSlice';
import { setJobCenterData } from '../redux/jobCenterSlice';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from "../data/constants";
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';

// const baseURL = process.env.ADZUNA_APP_URL;
// const appId = process.env.ADZUNA_APP_ID;
// const appKey = process.env.ADZUNA_APP_KEY;

export const getJobs = async (queryParams, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.get(`${ApiConfig.getJobs}?${queryParams}`);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      dispatch(setJobCenterData(resp?.data?.data?.results));
      return resp?.data?.data?.results;
    } else {
      toast.error(errorMessage(resp), 2500);
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      dispatch(setJobCenterData(null));
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), 2500);
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    dispatch(setJobCenterData(null));
    return false;
  }
};