import ApiConfig from '../config/ApiConfig';
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from '../data/constants';
import axios from 'axios';
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';
import { couldnotSave, saved, saving, resetStatus } from '../redux/saveSlice';

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const ideaRatingService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.rateIdea}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      setTimeout(() => {
        dispatch(resetStatus());
      }, 10000);
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    setTimeout(() => {
      dispatch(resetStatus());
    }, 10000);
    // showErrorMessage(e);
    return false;
  }
};
export const listIdeaService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.categorizedIdeas}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const addTopIdeaService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.addTopIdeas}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message);
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
  }
};
export const listIdeaGrade = async () => {
  try {
    const resp = await axios.get(`${ApiConfig.listIdeaGrades}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (error) {
    showErrorMessage(error);
    return false;
  }
};
